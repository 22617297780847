export enum ImportTypeFileExampleEnum {
  allTypes = 'Tout',
  territoires = 'Territoires',
  utilisateurs = 'Utilisateurs',
  userPatrimoine = 'Secteurs par patrimoine',
  userTerritoire = 'Secteurs par territoire',
  profils = 'Profils',
  noComposant = 'Déclaration absence composants',
  deletePatrimoine = 'Suppression patrimoines',
  progInterventions = 'Interventions',
  progPatrimoineInfos = 'Informations de patrimoines'
}
