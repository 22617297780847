import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { SocietePatrimoineHierarchie, SocietePatrimoineHierarchieEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { SocieteComposantFamille, SocieteComposantFamilleEntityState } from '@get/api-interfaces';
import { SocieteEspaceFamille, SocieteEspaceFamilleEntityState } from '@get/api-interfaces';
import { Campagne, CampagneEntityState } from '@get/api-interfaces';
import { SocieteTerritoire, SocieteTerritoireEntityState } from '@get/api-interfaces';
import { SocieteProfil, SocieteProfilEntityState } from '@get/api-interfaces';
import { Patrimoine, PatrimoineEntityState } from '@get/api-interfaces';
import { SocieteConfigAnalyseSynthese, SocieteConfigAnalyseSyntheseEntityState } from '@get/api-interfaces';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposant, ProgComposantEntityState } from '@get/api-interfaces';
import { ProgComposantGroupe, ProgComposantGroupeEntityState } from '@get/api-interfaces';
import { ProgComposantSousGroupe, ProgComposantSousGroupeEntityState } from '@get/api-interfaces';
import { ProgBiblioIntervention, ProgBiblioInterventionEntityState } from '@get/api-interfaces';
import { ProgBudget, ProgBudgetEntityState } from '@get/api-interfaces';
import { ProgUnite, ProgUniteEntityState } from '@get/api-interfaces';
import { ProgInterventionFamille, ProgInterventionFamilleEntityState } from '@get/api-interfaces';
import { ProgScenario, ProgScenarioEntityState } from '@get/api-interfaces';
import { ProgIntervention, ProgInterventionEntityState } from '@get/api-interfaces';
import { ProgEtatTechnique, ProgEtatTechniqueEntityState } from '@get/api-interfaces';
import { Organisation, OrganisationEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { SocieteState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const societeRelations: string[] = ['societeComposants','societePatrimoineHierarchies','societeCaracteristiques','societeComposantFamilles','societeEspaceFamilles','campagnes','societeTerritoires','societeProfils','patrimoines','societeConfigAnalyseSyntheses','composantGroupes','progComposants','progComposantGroupes','progComposantSousGroupes','progBiblioInterventions','progBudgets','progUnites','progInterventionFamilles','progScenarios','progInterventions','progEtatTechniques','organisations',];

export const { selectEntities, selectAll } = SocieteState.adapter.getSelectors();

export const selectSocieteState = createFeatureSelector<SocieteState.IState>(SocieteState.societeFeatureKey);

export const selectIsLoadedSociete = createSelector(
  selectSocieteState,
  (state: SocieteState.IState) => state.isLoaded
);

export const selectIsLoadingSociete = createSelector(
  selectSocieteState,
  (state: SocieteState.IState) => state.isLoading
);

export const selectIsReadySociete = createSelector(
  selectSocieteState,
  (state: SocieteState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedSociete = createSelector(
  selectSocieteState,
  (state: SocieteState.IState) => state.isLoaded && !state.isLoading
);

export const selectSocietesEntities = createSelector(selectSocieteState, selectEntities);

export const selectSocietesArray = createSelector(selectSocieteState, selectAll);

export const selectIdSocietesActive = createSelector(
  selectSocieteState,
  (state: SocieteState.IState) => state.actives
);

const societesInObject = (societes: Dictionary<SocieteEntityState>) => ({ societes })

const selectSocietesEntitiesDictionary = createSelector(selectSocietesEntities, societesInObject);

const selectAllSocietesObject = createSelector(selectSocietesEntities, societes => {
  return hydrateAll({ societes });
});

const selectOneSocieteDictionary = (idSociete : number) =>
  createSelector(selectSocietesEntities, societes => ({
    societes: { [idSociete]: societes[idSociete] }
  }));

const selectOneSocieteDictionaryWithoutChild = (idSociete : number) =>
  createSelector(selectSocietesEntities, societes => ({
    societe: societes[idSociete]
  }));

const selectActiveSocietesEntities = createSelector(
  selectIdSocietesActive,
  selectSocietesEntities,
  (actives: number[], societes: Dictionary<SocieteEntityState>) => getSocietesFromActives(actives, societes)
);

function getSocietesFromActives(
  actives: number[],
  societes: Dictionary<SocieteEntityState>
): Dictionary<SocieteEntityState> {
  return actives.reduce((acc, idActive) => {
    if (societes[idActive]) {
      acc[idActive] = societes[idActive];
    }
    return acc;
  }, {} as Dictionary<SocieteEntityState>);
}

const selectAllSocietesSelectors: Dictionary<Selector> = {};
export function selectAllSocietes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<Societe>(
      schema,
      selectAllSocietesSelectors,
      selectSocietesEntitiesDictionary,
      getRelationSelectors,
      societeRelations,
      hydrateAll,
      'societe'
    );
  } else {
    return selectAllSocietesObject;
  }
}

export function selectAllSocietesDictionary(
  schema: SelectSchema = {},
  customKey = 'societes'
): Selector {
  return createSelector(selectAllSocietes(schema), result => {
    const res = { [customKey]: {} as Dictionary<SocieteEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.societes.length; i++) {
      res[customKey][result.societes[i].idSociete] = result.societes[i];
    }
    return res;
  });
}

export function selectOneSociete(
  schema: SelectSchema = {},
  idSociete: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneSocieteDictionary(idSociete)];
  selectors.push(...getRelationSelectors(schema, societeRelations, 'societe'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneSocieteDictionaryWithoutChild(idSociete);
  }
}

export function selectActiveSocietes(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [
    createSelector(selectActiveSocietesEntities, societes => ({
      societes
    }))
  ];
  selectors.push(...getRelationSelectors(schema, societeRelations, 'societe'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  societes: Dictionary<SocieteEntityState>;
  organisations?: Dictionary<OrganisationEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  societePatrimoineHierarchies?: Dictionary<SocietePatrimoineHierarchieEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  societeComposantFamilles?: Dictionary<SocieteComposantFamilleEntityState>;
  societeEspaceFamilles?: Dictionary<SocieteEspaceFamilleEntityState>;
  campagnes?: Dictionary<CampagneEntityState>;
  societeTerritoires?: Dictionary<SocieteTerritoireEntityState>;
  societeProfils?: Dictionary<SocieteProfilEntityState>;
  patrimoines?: Dictionary<PatrimoineEntityState>;
  societeConfigAnalyseSyntheses?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>;
  composantGroupes?: Dictionary<ComposantGroupeEntityState>;
  progComposants?: Dictionary<ProgComposantEntityState>;
  progComposantGroupes?: Dictionary<ProgComposantGroupeEntityState>;
  progComposantSousGroupes?: Dictionary<ProgComposantSousGroupeEntityState>;
  progBiblioInterventions?: Dictionary<ProgBiblioInterventionEntityState>;
  progBudgets?: Dictionary<ProgBudgetEntityState>;
  progUnites?: Dictionary<ProgUniteEntityState>;
  progInterventionFamilles?: Dictionary<ProgInterventionFamilleEntityState>;
  progScenarios?: Dictionary<ProgScenarioEntityState>;
  progInterventions?: Dictionary<ProgInterventionEntityState>;
  progEtatTechniques?: Dictionary<ProgEtatTechniqueEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { societes: (Societe | null)[] } {
  const {
    societes,
    organisations,
    societeComposants,
    societePatrimoineHierarchies,
    societeCaracteristiques,
    societeComposantFamilles,
    societeEspaceFamilles,
    campagnes,
    societeTerritoires,
    societeProfils,
    patrimoines,
    societeConfigAnalyseSyntheses,
    composantGroupes,
    progComposants,
    progComposantGroupes,
    progComposantSousGroupes,
    progBiblioInterventions,
    progBudgets,
    progUnites,
    progInterventionFamilles,
    progScenarios,
    progInterventions,
    progEtatTechniques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    societes: Object.keys(societes).map(idSociete =>
      hydrate(
        societes[idSociete] as SocieteEntityState,
        organisations,
        societeComposants,
        societePatrimoineHierarchies,
        societeCaracteristiques,
        societeComposantFamilles,
        societeEspaceFamilles,
        campagnes,
        societeTerritoires,
        societeProfils,
        patrimoines,
        societeConfigAnalyseSyntheses,
        composantGroupes,
        progComposants,
        progComposantGroupes,
        progComposantSousGroupes,
        progBiblioInterventions,
        progBudgets,
        progUnites,
        progInterventionFamilles,
        progScenarios,
        progInterventions,
        progEtatTechniques
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { societe: SocieteEntityState | null } {
  const {
    societes,
    organisations,
    societeComposants,
    societePatrimoineHierarchies,
    societeCaracteristiques,
    societeComposantFamilles,
    societeEspaceFamilles,
    campagnes,
    societeTerritoires,
    societeProfils,
    patrimoines,
    societeConfigAnalyseSyntheses,
    composantGroupes,
    progComposants,
    progComposantGroupes,
    progComposantSousGroupes,
    progBiblioInterventions,
    progBudgets,
    progUnites,
    progInterventionFamilles,
    progScenarios,
    progInterventions,
    progEtatTechniques
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const societe = Object.values(societes)[0];
  return {
    societe: hydrate(
      societe as SocieteEntityState,
      organisations,
      societeComposants,
      societePatrimoineHierarchies,
      societeCaracteristiques,
      societeComposantFamilles,
      societeEspaceFamilles,
      campagnes,
      societeTerritoires,
      societeProfils,
      patrimoines,
      societeConfigAnalyseSyntheses,
      composantGroupes,
      progComposants,
      progComposantGroupes,
      progComposantSousGroupes,
      progBiblioInterventions,
      progBudgets,
      progUnites,
      progInterventionFamilles,
      progScenarios,
      progInterventions,
      progEtatTechniques
    )
  };
}

function hydrate(
  societe: SocieteEntityState,
  organisationEntities?: Dictionary<OrganisationEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  societePatrimoineHierarchieEntities?: Dictionary<SocietePatrimoineHierarchieEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  societeComposantFamilleEntities?: Dictionary<SocieteComposantFamilleEntityState>,
  societeEspaceFamilleEntities?: Dictionary<SocieteEspaceFamilleEntityState>,
  campagneEntities?: Dictionary<CampagneEntityState>,
  societeTerritoireEntities?: Dictionary<SocieteTerritoireEntityState>,
  societeProfilEntities?: Dictionary<SocieteProfilEntityState>,
  patrimoineEntities?: Dictionary<PatrimoineEntityState>,
  societeConfigAnalyseSyntheseEntities?: Dictionary<SocieteConfigAnalyseSyntheseEntityState>,
  composantGroupeEntities?: Dictionary<ComposantGroupeEntityState>,
  progComposantEntities?: Dictionary<ProgComposantEntityState>,
  progComposantGroupeEntities?: Dictionary<ProgComposantGroupeEntityState>,
  progComposantSousGroupeEntities?: Dictionary<ProgComposantSousGroupeEntityState>,
  progBiblioInterventionEntities?: Dictionary<ProgBiblioInterventionEntityState>,
  progBudgetEntities?: Dictionary<ProgBudgetEntityState>,
  progUniteEntities?: Dictionary<ProgUniteEntityState>,
  progInterventionFamilleEntities?: Dictionary<ProgInterventionFamilleEntityState>,
  progScenarioEntities?: Dictionary<ProgScenarioEntityState>,
  progInterventionEntities?: Dictionary<ProgInterventionEntityState>,
  progEtatTechniqueEntities?: Dictionary<ProgEtatTechniqueEntityState>,
): Societe | null {
  if (!societe) {
    return null;
  }

  const societeHydrated: SocieteEntityState = { ...societe };
  if (organisationEntities) {
    societeHydrated.organisation = organisationEntities[societe.organisation as number] as Organisation;
  } else {
    delete societeHydrated.organisation;
  }

  if (societeComposantEntities) {
    societeHydrated.societeComposants = ((societeHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete societeHydrated.societeComposants;
  }

  if (societePatrimoineHierarchieEntities) {
    societeHydrated.societePatrimoineHierarchies = ((societeHydrated.societePatrimoineHierarchies as number[]) || []).map(
      id => societePatrimoineHierarchieEntities[id]
    ) as SocietePatrimoineHierarchie[];
  } else {
    delete societeHydrated.societePatrimoineHierarchies;
  }

  if (societeCaracteristiqueEntities) {
    societeHydrated.societeCaracteristiques = ((societeHydrated.societeCaracteristiques as number[]) || []).map(
      id => societeCaracteristiqueEntities[id]
    ) as SocieteCaracteristique[];
  } else {
    delete societeHydrated.societeCaracteristiques;
  }

  if (societeComposantFamilleEntities) {
    societeHydrated.societeComposantFamilles = ((societeHydrated.societeComposantFamilles as number[]) || []).map(
      id => societeComposantFamilleEntities[id]
    ) as SocieteComposantFamille[];
  } else {
    delete societeHydrated.societeComposantFamilles;
  }

  if (societeEspaceFamilleEntities) {
    societeHydrated.societeEspaceFamilles = ((societeHydrated.societeEspaceFamilles as number[]) || []).map(
      id => societeEspaceFamilleEntities[id]
    ) as SocieteEspaceFamille[];
  } else {
    delete societeHydrated.societeEspaceFamilles;
  }

  if (campagneEntities) {
    societeHydrated.campagnes = ((societeHydrated.campagnes as number[]) || []).map(
      id => campagneEntities[id]
    ) as Campagne[];
  } else {
    delete societeHydrated.campagnes;
  }

  if (societeTerritoireEntities) {
    societeHydrated.societeTerritoires = ((societeHydrated.societeTerritoires as number[]) || []).map(
      id => societeTerritoireEntities[id]
    ) as SocieteTerritoire[];
  } else {
    delete societeHydrated.societeTerritoires;
  }

  if (societeProfilEntities) {
    societeHydrated.societeProfils = ((societeHydrated.societeProfils as number[]) || []).map(
      id => societeProfilEntities[id]
    ) as SocieteProfil[];
  } else {
    delete societeHydrated.societeProfils;
  }

  if (patrimoineEntities) {
    societeHydrated.patrimoines = ((societeHydrated.patrimoines as number[]) || []).map(
      id => patrimoineEntities[id]
    ) as Patrimoine[];
  } else {
    delete societeHydrated.patrimoines;
  }

  if (societeConfigAnalyseSyntheseEntities) {
    societeHydrated.societeConfigAnalyseSyntheses = ((societeHydrated.societeConfigAnalyseSyntheses as number[]) || []).map(
      id => societeConfigAnalyseSyntheseEntities[id]
    ) as SocieteConfigAnalyseSynthese[];
  } else {
    delete societeHydrated.societeConfigAnalyseSyntheses;
  }

  if (composantGroupeEntities) {
    societeHydrated.composantGroupes = ((societeHydrated.composantGroupes as number[]) || []).map(
      id => composantGroupeEntities[id]
    ) as ComposantGroupe[];
  } else {
    delete societeHydrated.composantGroupes;
  }

  if (progComposantEntities) {
    societeHydrated.progComposants = ((societeHydrated.progComposants as number[]) || []).map(
      id => progComposantEntities[id]
    ) as ProgComposant[];
  } else {
    delete societeHydrated.progComposants;
  }

  if (progComposantGroupeEntities) {
    societeHydrated.progComposantGroupes = ((societeHydrated.progComposantGroupes as number[]) || []).map(
      id => progComposantGroupeEntities[id]
    ) as ProgComposantGroupe[];
  } else {
    delete societeHydrated.progComposantGroupes;
  }

  if (progComposantSousGroupeEntities) {
    societeHydrated.progComposantSousGroupes = ((societeHydrated.progComposantSousGroupes as number[]) || []).map(
      id => progComposantSousGroupeEntities[id]
    ) as ProgComposantSousGroupe[];
  } else {
    delete societeHydrated.progComposantSousGroupes;
  }

  if (progBiblioInterventionEntities) {
    societeHydrated.progBiblioInterventions = ((societeHydrated.progBiblioInterventions as number[]) || []).map(
      id => progBiblioInterventionEntities[id]
    ) as ProgBiblioIntervention[];
  } else {
    delete societeHydrated.progBiblioInterventions;
  }

  if (progBudgetEntities) {
    societeHydrated.progBudgets = ((societeHydrated.progBudgets as number[]) || []).map(
      id => progBudgetEntities[id]
    ) as ProgBudget[];
  } else {
    delete societeHydrated.progBudgets;
  }

  if (progUniteEntities) {
    societeHydrated.progUnites = ((societeHydrated.progUnites as number[]) || []).map(
      id => progUniteEntities[id]
    ) as ProgUnite[];
  } else {
    delete societeHydrated.progUnites;
  }

  if (progInterventionFamilleEntities) {
    societeHydrated.progInterventionFamilles = ((societeHydrated.progInterventionFamilles as number[]) || []).map(
      id => progInterventionFamilleEntities[id]
    ) as ProgInterventionFamille[];
  } else {
    delete societeHydrated.progInterventionFamilles;
  }

  if (progScenarioEntities) {
    societeHydrated.progScenarios = ((societeHydrated.progScenarios as number[]) || []).map(
      id => progScenarioEntities[id]
    ) as ProgScenario[];
  } else {
    delete societeHydrated.progScenarios;
  }

  if (progInterventionEntities) {
    societeHydrated.progInterventions = ((societeHydrated.progInterventions as number[]) || []).map(
      id => progInterventionEntities[id]
    ) as ProgIntervention[];
  } else {
    delete societeHydrated.progInterventions;
  }

  if (progEtatTechniqueEntities) {
    societeHydrated.progEtatTechniques = ((societeHydrated.progEtatTechniques as number[]) || []).map(
      id => progEtatTechniqueEntities[id]
    ) as ProgEtatTechnique[];
  } else {
    delete societeHydrated.progEtatTechniques;
  }

  return societeHydrated as Societe;
}
