import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ComposantGroupe, ComposantGroupeEntityState } from '@get/api-interfaces';
import { SocieteComposant, SocieteComposantEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { Fichier, FichierEntityState } from '@get/api-interfaces';
import { Societe, SocieteEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { ComposantGroupeState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const composantGroupeRelations: string[] = ['societeComposants','composantTemplates','fichiers','societes',];

export const { selectEntities, selectAll } = ComposantGroupeState.adapter.getSelectors();

export const selectComposantGroupeState = createFeatureSelector<ComposantGroupeState.IState>(ComposantGroupeState.composantGroupeFeatureKey);

export const selectIsLoadedComposantGroupe = createSelector(
  selectComposantGroupeState,
  (state: ComposantGroupeState.IState) => state.isLoaded
);

export const selectIsLoadingComposantGroupe = createSelector(
  selectComposantGroupeState,
  (state: ComposantGroupeState.IState) => state.isLoading
);

export const selectIsReadyComposantGroupe = createSelector(
  selectComposantGroupeState,
  (state: ComposantGroupeState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedComposantGroupe = createSelector(
  selectComposantGroupeState,
  (state: ComposantGroupeState.IState) => state.isLoaded && !state.isLoading
);

export const selectComposantGroupesEntities = createSelector(selectComposantGroupeState, selectEntities);

export const selectComposantGroupesArray = createSelector(selectComposantGroupeState, selectAll);

const composantGroupesInObject = (composantGroupes: Dictionary<ComposantGroupeEntityState>) => ({ composantGroupes })

const selectComposantGroupesEntitiesDictionary = createSelector(selectComposantGroupesEntities, composantGroupesInObject);

const selectAllComposantGroupesObject = createSelector(selectComposantGroupesEntities, composantGroupes => {
  return hydrateAll({ composantGroupes });
});

const selectOneComposantGroupeDictionary = (idComposantGroupe : number) =>
  createSelector(selectComposantGroupesEntities, composantGroupes => ({
    composantGroupes: { [idComposantGroupe]: composantGroupes[idComposantGroupe] }
  }));

const selectOneComposantGroupeDictionaryWithoutChild = (idComposantGroupe : number) =>
  createSelector(selectComposantGroupesEntities, composantGroupes => ({
    composantGroupe: composantGroupes[idComposantGroupe]
  }));

const selectAllComposantGroupesSelectors: Dictionary<Selector> = {};
export function selectAllComposantGroupes(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<ComposantGroupe>(
      schema,
      selectAllComposantGroupesSelectors,
      selectComposantGroupesEntitiesDictionary,
      getRelationSelectors,
      composantGroupeRelations,
      hydrateAll,
      'composantGroupe'
    );
  } else {
    return selectAllComposantGroupesObject;
  }
}

export function selectAllComposantGroupesDictionary(
  schema: SelectSchema = {},
  customKey = 'composantGroupes'
): Selector {
  return createSelector(selectAllComposantGroupes(schema), result => {
    const res = { [customKey]: {} as Dictionary<ComposantGroupeEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.composantGroupes.length; i++) {
      res[customKey][result.composantGroupes[i].idComposantGroupe] = result.composantGroupes[i];
    }
    return res;
  });
}

export function selectOneComposantGroupe(
  schema: SelectSchema = {},
  idComposantGroupe: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneComposantGroupeDictionary(idComposantGroupe)];
  selectors.push(...getRelationSelectors(schema, composantGroupeRelations, 'composantGroupe'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneComposantGroupeDictionaryWithoutChild(idComposantGroupe);
  }
}

interface hydrateArgs {
  composantGroupes: Dictionary<ComposantGroupeEntityState>;
  fichiers?: Dictionary<FichierEntityState>;
  societes?: Dictionary<SocieteEntityState>;
  societeComposants?: Dictionary<SocieteComposantEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { composantGroupes: (ComposantGroupe | null)[] } {
  const {
    composantGroupes,
    fichiers,
    societes,
    societeComposants,
    composantTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    composantGroupes: Object.keys(composantGroupes).map(idComposantGroupe =>
      hydrate(
        composantGroupes[idComposantGroupe] as ComposantGroupeEntityState,
        fichiers,
        societes,
        societeComposants,
        composantTemplates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { composantGroupe: ComposantGroupeEntityState | null } {
  const {
    composantGroupes,
    fichiers,
    societes,
    societeComposants,
    composantTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const composantGroupe = Object.values(composantGroupes)[0];
  return {
    composantGroupe: hydrate(
      composantGroupe as ComposantGroupeEntityState,
      fichiers,
      societes,
      societeComposants,
      composantTemplates
    )
  };
}

function hydrate(
  composantGroupe: ComposantGroupeEntityState,
  fichierEntities?: Dictionary<FichierEntityState>,
  societeEntities?: Dictionary<SocieteEntityState>,
  societeComposantEntities?: Dictionary<SocieteComposantEntityState>,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
): ComposantGroupe | null {
  if (!composantGroupe) {
    return null;
  }

  const composantGroupeHydrated: ComposantGroupeEntityState = { ...composantGroupe };
  if (fichierEntities) {
    composantGroupeHydrated.fichier = fichierEntities[composantGroupe.fichier as number] as Fichier;
  } else {
    delete composantGroupeHydrated.fichier;
  }
  if (societeEntities) {
    composantGroupeHydrated.societe = societeEntities[composantGroupe.societe as number] as Societe;
  } else {
    delete composantGroupeHydrated.societe;
  }

  if (societeComposantEntities) {
    composantGroupeHydrated.societeComposants = ((composantGroupeHydrated.societeComposants as number[]) || []).map(
      id => societeComposantEntities[id]
    ) as SocieteComposant[];
  } else {
    delete composantGroupeHydrated.societeComposants;
  }

  if (composantTemplateEntities) {
    composantGroupeHydrated.composantTemplates = ((composantGroupeHydrated.composantTemplates as number[]) || []).map(
      id => composantTemplateEntities[id]
    ) as ComposantTemplate[];
  } else {
    delete composantGroupeHydrated.composantTemplates;
  }

  return composantGroupeHydrated as ComposantGroupe;
}
