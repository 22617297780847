import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { SocieteGeneratedActions } from '@get/store/actions';
import { SocieteState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { SocieteEntityState } from '@get/api-interfaces';

export const societeReducersGeneratedFunctions: ReducerTypes<SocieteState.IState, readonly ActionCreator[]>[] = [
  on(SocieteGeneratedActions.getManySocietes, (state: SocieteState.IState) => setLoadingsState(state, true)),
  on(SocieteGeneratedActions.upsertOneSociete, (state: SocieteState.IState) => setLoadingsState(state, true)),

  on(SocieteGeneratedActions.upsertManySocietesSuccess, (state: SocieteState.IState, { societes }) =>
    SocieteState.adapter.upsertMany(societes, setLoadingsState(state, false))
  ),
  on(SocieteGeneratedActions.deleteOneSocieteSuccess, (state: SocieteState.IState, { idSociete }) =>
    SocieteState.adapter.removeOne(idSociete, setLoadingsState(state, false))
  ),
  on(SocieteGeneratedActions.clearActivesSocietes, (state: SocieteState.IState) => ({ ...state, actives: [] })),
  on(SocieteGeneratedActions.addManyActivesSocietes, (state: SocieteState.IState, { idSocietes }) => ({
    ...state,
    actives: state.actives.concat(idSocietes)
  })),
  on(SocieteGeneratedActions.deleteOneActiveSociete, (state: SocieteState.IState, { idSociete }) => ({
    ...state,
    actives: state.actives.filter(id => id !== idSociete)
  })),

  on(SocieteGeneratedActions.clearSocietes, () => SocieteState.initialState),

  on(
    SocieteGeneratedActions.addManySocieteComposantSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteComposants }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeComposants = (state.entities[idSociete]?.societeComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeComposants: societeComposants.concat(
              idSocieteComposants.filter(id => societeComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteComposantSuccess,
    (state: SocieteState.IState, { idSocieteComposants, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeComposants) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeComposants: (state.entities[idSociete]?.societeComposants as number[])?.filter(
                (idSocieteComposant: number) => !idSocieteComposants.some((id: number) => id === idSocieteComposant)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocietePatrimoineHierarchieSuccess,
    (state: SocieteState.IState, { idSociete, idSocietePatrimoineHierarchies }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societePatrimoineHierarchies = (state.entities[idSociete]?.societePatrimoineHierarchies as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societePatrimoineHierarchies: societePatrimoineHierarchies.concat(
              idSocietePatrimoineHierarchies.filter(id => societePatrimoineHierarchies.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocietePatrimoineHierarchieSuccess,
    (state: SocieteState.IState, { idSocietePatrimoineHierarchies, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societePatrimoineHierarchies) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societePatrimoineHierarchies: (state.entities[idSociete]?.societePatrimoineHierarchies as number[])?.filter(
                (idSocietePatrimoineHierarchie: number) => !idSocietePatrimoineHierarchies.some((id: number) => id === idSocietePatrimoineHierarchie)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteCaracteristiqueSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteCaracteristiques }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeCaracteristiques = (state.entities[idSociete]?.societeCaracteristiques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeCaracteristiques: societeCaracteristiques.concat(
              idSocieteCaracteristiques.filter(id => societeCaracteristiques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteCaracteristiqueSuccess,
    (state: SocieteState.IState, { idSocieteCaracteristiques, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeCaracteristiques) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeCaracteristiques: (state.entities[idSociete]?.societeCaracteristiques as number[])?.filter(
                (idSocieteCaracteristique: number) => !idSocieteCaracteristiques.some((id: number) => id === idSocieteCaracteristique)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteComposantFamilleSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteComposantFamilles }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeComposantFamilles = (state.entities[idSociete]?.societeComposantFamilles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeComposantFamilles: societeComposantFamilles.concat(
              idSocieteComposantFamilles.filter(id => societeComposantFamilles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteComposantFamilleSuccess,
    (state: SocieteState.IState, { idSocieteComposantFamilles, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeComposantFamilles) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeComposantFamilles: (state.entities[idSociete]?.societeComposantFamilles as number[])?.filter(
                (idSocieteComposantFamille: number) => !idSocieteComposantFamilles.some((id: number) => id === idSocieteComposantFamille)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteEspaceFamilleSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteEspaceFamilles }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeEspaceFamilles = (state.entities[idSociete]?.societeEspaceFamilles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeEspaceFamilles: societeEspaceFamilles.concat(
              idSocieteEspaceFamilles.filter(id => societeEspaceFamilles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteEspaceFamilleSuccess,
    (state: SocieteState.IState, { idSocieteEspaceFamilles, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeEspaceFamilles) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeEspaceFamilles: (state.entities[idSociete]?.societeEspaceFamilles as number[])?.filter(
                (idSocieteEspaceFamille: number) => !idSocieteEspaceFamilles.some((id: number) => id === idSocieteEspaceFamille)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyCampagneSuccess,
    (state: SocieteState.IState, { idSociete, idCampagnes }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const campagnes = (state.entities[idSociete]?.campagnes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            campagnes: campagnes.concat(
              idCampagnes.filter(id => campagnes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyCampagneSuccess,
    (state: SocieteState.IState, { idCampagnes, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.campagnes) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              campagnes: (state.entities[idSociete]?.campagnes as number[])?.filter(
                (idCampagne: number) => !idCampagnes.some((id: number) => id === idCampagne)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteTerritoireSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteTerritoires }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeTerritoires = (state.entities[idSociete]?.societeTerritoires as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeTerritoires: societeTerritoires.concat(
              idSocieteTerritoires.filter(id => societeTerritoires.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteTerritoireSuccess,
    (state: SocieteState.IState, { idSocieteTerritoires, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeTerritoires) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeTerritoires: (state.entities[idSociete]?.societeTerritoires as number[])?.filter(
                (idSocieteTerritoire: number) => !idSocieteTerritoires.some((id: number) => id === idSocieteTerritoire)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteProfilSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteProfils }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeProfils = (state.entities[idSociete]?.societeProfils as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeProfils: societeProfils.concat(
              idSocieteProfils.filter(id => societeProfils.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteProfilSuccess,
    (state: SocieteState.IState, { idSocieteProfils, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeProfils) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeProfils: (state.entities[idSociete]?.societeProfils as number[])?.filter(
                (idSocieteProfil: number) => !idSocieteProfils.some((id: number) => id === idSocieteProfil)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyPatrimoineSuccess,
    (state: SocieteState.IState, { idSociete, idPatrimoines }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const patrimoines = (state.entities[idSociete]?.patrimoines as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            patrimoines: patrimoines.concat(
              idPatrimoines.filter(id => patrimoines.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyPatrimoineSuccess,
    (state: SocieteState.IState, { idPatrimoines, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.patrimoines) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              patrimoines: (state.entities[idSociete]?.patrimoines as number[])?.filter(
                (idPatrimoine: number) => !idPatrimoines.some((id: number) => id === idPatrimoine)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManySocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteState.IState, { idSociete, idSocieteConfigAnalyseSyntheses }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const societeConfigAnalyseSyntheses = (state.entities[idSociete]?.societeConfigAnalyseSyntheses as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            societeConfigAnalyseSyntheses: societeConfigAnalyseSyntheses.concat(
              idSocieteConfigAnalyseSyntheses.filter(id => societeConfigAnalyseSyntheses.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManySocieteConfigAnalyseSyntheseSuccess,
    (state: SocieteState.IState, { idSocieteConfigAnalyseSyntheses, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.societeConfigAnalyseSyntheses) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              societeConfigAnalyseSyntheses: (state.entities[idSociete]?.societeConfigAnalyseSyntheses as number[])?.filter(
                (idSocieteConfigAnalyseSynthese: number) => !idSocieteConfigAnalyseSyntheses.some((id: number) => id === idSocieteConfigAnalyseSynthese)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyComposantGroupeSuccess,
    (state: SocieteState.IState, { idSociete, idComposantGroupes }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const composantGroupes = (state.entities[idSociete]?.composantGroupes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            composantGroupes: composantGroupes.concat(
              idComposantGroupes.filter(id => composantGroupes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyComposantGroupeSuccess,
    (state: SocieteState.IState, { idComposantGroupes, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.composantGroupes) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              composantGroupes: (state.entities[idSociete]?.composantGroupes as number[])?.filter(
                (idComposantGroupe: number) => !idComposantGroupes.some((id: number) => id === idComposantGroupe)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgComposantSuccess,
    (state: SocieteState.IState, { idSociete, idProgComposants }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progComposants = (state.entities[idSociete]?.progComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progComposants: progComposants.concat(
              idProgComposants.filter(id => progComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgComposantSuccess,
    (state: SocieteState.IState, { idProgComposants, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progComposants) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progComposants: (state.entities[idSociete]?.progComposants as number[])?.filter(
                (idProgComposant: number) => !idProgComposants.some((id: number) => id === idProgComposant)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgComposantGroupeSuccess,
    (state: SocieteState.IState, { idSociete, idProgComposantGroupes }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progComposantGroupes = (state.entities[idSociete]?.progComposantGroupes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progComposantGroupes: progComposantGroupes.concat(
              idProgComposantGroupes.filter(id => progComposantGroupes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgComposantGroupeSuccess,
    (state: SocieteState.IState, { idProgComposantGroupes, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progComposantGroupes) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progComposantGroupes: (state.entities[idSociete]?.progComposantGroupes as number[])?.filter(
                (idProgComposantGroupe: number) => !idProgComposantGroupes.some((id: number) => id === idProgComposantGroupe)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgComposantSousGroupeSuccess,
    (state: SocieteState.IState, { idSociete, idProgComposantSousGroupes }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progComposantSousGroupes = (state.entities[idSociete]?.progComposantSousGroupes as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progComposantSousGroupes: progComposantSousGroupes.concat(
              idProgComposantSousGroupes.filter(id => progComposantSousGroupes.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgComposantSousGroupeSuccess,
    (state: SocieteState.IState, { idProgComposantSousGroupes, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progComposantSousGroupes) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progComposantSousGroupes: (state.entities[idSociete]?.progComposantSousGroupes as number[])?.filter(
                (idProgComposantSousGroupe: number) => !idProgComposantSousGroupes.some((id: number) => id === idProgComposantSousGroupe)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgBiblioInterventionSuccess,
    (state: SocieteState.IState, { idSociete, idProgBiblioInterventions }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progBiblioInterventions = (state.entities[idSociete]?.progBiblioInterventions as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progBiblioInterventions: progBiblioInterventions.concat(
              idProgBiblioInterventions.filter(id => progBiblioInterventions.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgBiblioInterventionSuccess,
    (state: SocieteState.IState, { idProgBiblioInterventions, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progBiblioInterventions) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progBiblioInterventions: (state.entities[idSociete]?.progBiblioInterventions as number[])?.filter(
                (idProgBiblioIntervention: number) => !idProgBiblioInterventions.some((id: number) => id === idProgBiblioIntervention)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgBudgetSuccess,
    (state: SocieteState.IState, { idSociete, idProgBudgets }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progBudgets = (state.entities[idSociete]?.progBudgets as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progBudgets: progBudgets.concat(
              idProgBudgets.filter(id => progBudgets.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgBudgetSuccess,
    (state: SocieteState.IState, { idProgBudgets, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progBudgets) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progBudgets: (state.entities[idSociete]?.progBudgets as number[])?.filter(
                (idProgBudget: number) => !idProgBudgets.some((id: number) => id === idProgBudget)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgUniteSuccess,
    (state: SocieteState.IState, { idSociete, idProgUnites }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progUnites = (state.entities[idSociete]?.progUnites as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progUnites: progUnites.concat(
              idProgUnites.filter(id => progUnites.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgUniteSuccess,
    (state: SocieteState.IState, { idProgUnites, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progUnites) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progUnites: (state.entities[idSociete]?.progUnites as number[])?.filter(
                (idProgUnite: number) => !idProgUnites.some((id: number) => id === idProgUnite)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgInterventionFamilleSuccess,
    (state: SocieteState.IState, { idSociete, idProgInterventionFamilles }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progInterventionFamilles = (state.entities[idSociete]?.progInterventionFamilles as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progInterventionFamilles: progInterventionFamilles.concat(
              idProgInterventionFamilles.filter(id => progInterventionFamilles.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgInterventionFamilleSuccess,
    (state: SocieteState.IState, { idProgInterventionFamilles, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progInterventionFamilles) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progInterventionFamilles: (state.entities[idSociete]?.progInterventionFamilles as number[])?.filter(
                (idProgInterventionFamille: number) => !idProgInterventionFamilles.some((id: number) => id === idProgInterventionFamille)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgScenarioSuccess,
    (state: SocieteState.IState, { idSociete, idProgScenarios }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progScenarios = (state.entities[idSociete]?.progScenarios as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progScenarios: progScenarios.concat(
              idProgScenarios.filter(id => progScenarios.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgScenarioSuccess,
    (state: SocieteState.IState, { idProgScenarios, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progScenarios) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progScenarios: (state.entities[idSociete]?.progScenarios as number[])?.filter(
                (idProgScenario: number) => !idProgScenarios.some((id: number) => id === idProgScenario)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addManyProgEtatTechniqueSuccess,
    (state: SocieteState.IState, { idSociete, idProgEtatTechniques }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      const progEtatTechniques = (state.entities[idSociete]?.progEtatTechniques as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            progEtatTechniques: progEtatTechniques.concat(
              idProgEtatTechniques.filter(id => progEtatTechniques.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyProgEtatTechniqueSuccess,
    (state: SocieteState.IState, { idProgEtatTechniques, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.progEtatTechniques) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              progEtatTechniques: (state.entities[idSociete]?.progEtatTechniques as number[])?.filter(
                (idProgEtatTechnique: number) => !idProgEtatTechniques.some((id: number) => id === idProgEtatTechnique)
              )
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.addOrganisationSuccess,
    (state: SocieteState.IState, { idSociete, idOrganisation }) => {
      if (!state.entities[idSociete]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idSociete]: {
            ...state.entities[idSociete],
            organisation: idOrganisation
          }
        }
      };
    }
  ),

  on(
    SocieteGeneratedActions.deleteManyOrganisationSuccess,
    (state: SocieteState.IState, { idOrganisations, idSocietes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idSocietes.reduce((entities, idSociete) => {
            if (!state.entities[idSociete]?.organisation) {
              return entities;
            }
            entities[idSociete] = {
              ...state.entities[idSociete],
              organisation: idOrganisations.some(
                (idOrganisation: number) => idOrganisation === state.entities[idSociete]?.organisation
              )
                ? undefined
                : state.entities[idSociete]?.organisation
            } as SocieteEntityState;
            return entities;
          }, {} as Dictionary<SocieteEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: SocieteState.IState,
  isLoading: boolean,
  isLoaded = true
): SocieteState.IState {
  return { ...state, isLoaded, isLoading };
}
