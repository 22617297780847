import { ActionCreator, on, ReducerTypes } from '@ngrx/store';
import { ComposantGroupeGeneratedActions } from '@get/store/actions';
import { ComposantGroupeState } from '@get/store/states';
import { Dictionary } from '@ngrx/entity';
import { ComposantGroupeEntityState } from '@get/api-interfaces';

export const composantGroupeReducersGeneratedFunctions: ReducerTypes<ComposantGroupeState.IState, readonly ActionCreator[]>[] = [
  on(ComposantGroupeGeneratedActions.getManyComposantGroupes, (state: ComposantGroupeState.IState) => setLoadingsState(state, true)),
  on(ComposantGroupeGeneratedActions.upsertOneComposantGroupe, (state: ComposantGroupeState.IState) => setLoadingsState(state, true)),

  on(ComposantGroupeGeneratedActions.upsertManyComposantGroupesSuccess, (state: ComposantGroupeState.IState, { composantGroupes }) =>
    ComposantGroupeState.adapter.upsertMany(composantGroupes, setLoadingsState(state, false))
  ),
  on(ComposantGroupeGeneratedActions.deleteOneComposantGroupe, (state: ComposantGroupeState.IState) => setLoadingsState(state, true)),
  on(ComposantGroupeGeneratedActions.deleteOneComposantGroupeSuccess, (state: ComposantGroupeState.IState, { idComposantGroupe }) =>
    ComposantGroupeState.adapter.removeOne(idComposantGroupe, setLoadingsState(state, false))
  ),

  on(ComposantGroupeGeneratedActions.clearComposantGroupes, () => ComposantGroupeState.initialState),

  on(
    ComposantGroupeGeneratedActions.addManySocieteComposantSuccess,
    (state: ComposantGroupeState.IState, { idComposantGroupe, idSocieteComposants }) => {
      if (!state.entities[idComposantGroupe]) {
        return state;
      }
      const societeComposants = (state.entities[idComposantGroupe]?.societeComposants as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantGroupe]: {
            ...state.entities[idComposantGroupe],
            societeComposants: societeComposants.concat(
              idSocieteComposants.filter(id => societeComposants.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.deleteManySocieteComposantSuccess,
    (state: ComposantGroupeState.IState, { idSocieteComposants, idComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantGroupes.reduce((entities, idComposantGroupe) => {
            if (!state.entities[idComposantGroupe]?.societeComposants) {
              return entities;
            }
            entities[idComposantGroupe] = {
              ...state.entities[idComposantGroupe],
              societeComposants: (state.entities[idComposantGroupe]?.societeComposants as number[])?.filter(
                (idSocieteComposant: number) => !idSocieteComposants.some((id: number) => id === idSocieteComposant)
              )
            } as ComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ComposantGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.addManyComposantTemplateSuccess,
    (state: ComposantGroupeState.IState, { idComposantGroupe, idComposantTemplates }) => {
      if (!state.entities[idComposantGroupe]) {
        return state;
      }
      const composantTemplates = (state.entities[idComposantGroupe]?.composantTemplates as number[]) || [];
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantGroupe]: {
            ...state.entities[idComposantGroupe],
            composantTemplates: composantTemplates.concat(
              idComposantTemplates.filter(id => composantTemplates.indexOf(id) < 0)
            )
          }
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.deleteManyComposantTemplateSuccess,
    (state: ComposantGroupeState.IState, { idComposantTemplates, idComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantGroupes.reduce((entities, idComposantGroupe) => {
            if (!state.entities[idComposantGroupe]?.composantTemplates) {
              return entities;
            }
            entities[idComposantGroupe] = {
              ...state.entities[idComposantGroupe],
              composantTemplates: (state.entities[idComposantGroupe]?.composantTemplates as number[])?.filter(
                (idComposantTemplate: number) => !idComposantTemplates.some((id: number) => id === idComposantTemplate)
              )
            } as ComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ComposantGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.addFichierSuccess,
    (state: ComposantGroupeState.IState, { idComposantGroupe, idFichier }) => {
      if (!state.entities[idComposantGroupe]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantGroupe]: {
            ...state.entities[idComposantGroupe],
            fichier: idFichier
          }
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.deleteManyFichierSuccess,
    (state: ComposantGroupeState.IState, { idFichiers, idComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantGroupes.reduce((entities, idComposantGroupe) => {
            if (!state.entities[idComposantGroupe]?.fichier) {
              return entities;
            }
            entities[idComposantGroupe] = {
              ...state.entities[idComposantGroupe],
              fichier: idFichiers.some(
                (idFichier: number) => idFichier === state.entities[idComposantGroupe]?.fichier
              )
                ? undefined
                : state.entities[idComposantGroupe]?.fichier
            } as ComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ComposantGroupeEntityState>)
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.addSocieteSuccess,
    (state: ComposantGroupeState.IState, { idComposantGroupe, idSociete }) => {
      if (!state.entities[idComposantGroupe]) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [idComposantGroupe]: {
            ...state.entities[idComposantGroupe],
            societe: idSociete
          }
        }
      };
    }
  ),

  on(
    ComposantGroupeGeneratedActions.deleteManySocieteSuccess,
    (state: ComposantGroupeState.IState, { idSocietes, idComposantGroupes }) => {
      return {
        ...state,
        entities: {
          ...state.entities,
          ...idComposantGroupes.reduce((entities, idComposantGroupe) => {
            if (!state.entities[idComposantGroupe]?.societe) {
              return entities;
            }
            entities[idComposantGroupe] = {
              ...state.entities[idComposantGroupe],
              societe: idSocietes.some(
                (idSociete: number) => idSociete === state.entities[idComposantGroupe]?.societe
              )
                ? undefined
                : state.entities[idComposantGroupe]?.societe
            } as ComposantGroupeEntityState;
            return entities;
          }, {} as Dictionary<ComposantGroupeEntityState>)
        }
      };
    }
  ),
];

export function setLoadingsState(
  state: ComposantGroupeState.IState,
  isLoading: boolean,
  isLoaded = true
): ComposantGroupeState.IState {
  return { ...state, isLoaded, isLoading };
}
