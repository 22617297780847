import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, switchMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '@get/store/configs/reducers';
import { StoreActionType } from '@enums';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { CaracteristiqueTemplateApiService } from '@get/store/api-services';
import { CaracteristiqueTemplateGeneratedActions } from '@get/store/actions';
import { getActionsToNormalizeCaracteristiqueTemplate } from '@get/store/configs/normalization';
import { CaracteristiqueTemplateSelectors } from '@get/store/selectors';
import { CaracteristiqueTemplateRelationsIds } from '@get/store/ids-interfaces';
import { ComposantTemplateGeneratedActions } from '@get/store/actions';
import { PatrimoineHierarchieTemplateGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristiqueGeneratedActions } from '@get/store/actions';
import { SocieteCaracteristique } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplateGeneratedActions } from '@get/store/actions';
import { CaracteristiqueChoixTemplate } from '@get/api-interfaces';

export function getDefaultAddCaracteristiqueTemplateActions(caracteristiqueTemplate: CaracteristiqueTemplateEntityState, ids?: CaracteristiqueTemplateRelationsIds): Action[] {
  const actions: Action[] = [CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert({ caracteristiqueTemplates: [caracteristiqueTemplate] })];

  if (ids?.composantTemplate) {
    actions.push(
      ComposantTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
        idComposantTemplate: ids.composantTemplate,
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate]
      })
    );
    actions.push(
      CaracteristiqueTemplateGeneratedActions.addComposantTemplateSuccess({
        idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
        idComposantTemplate: ids.composantTemplate
      })
    );
  }

  if (ids?.patrimoineHierarchieTemplate) {
    actions.push(
      PatrimoineHierarchieTemplateGeneratedActions.addManyCaracteristiqueTemplateSuccess({
        idPatrimoineHierarchieTemplate: ids.patrimoineHierarchieTemplate,
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate]
      })
    );
    actions.push(
      CaracteristiqueTemplateGeneratedActions.addPatrimoineHierarchieTemplateSuccess({
        idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
        idPatrimoineHierarchieTemplate: ids.patrimoineHierarchieTemplate
      })
    );
  }

  if (ids?.societeCaracteristiques) {
    if (!Array.isArray(ids.societeCaracteristiques)) {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertOneSocieteCaracteristique({
          societeCaracteristique: {
            idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
            idSocieteCaracteristique: ids.societeCaracteristiques as number
          } as SocieteCaracteristique
        })
      );
      actions.push(
        CaracteristiqueTemplateGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
          idSocieteCaracteristiques: [ids.societeCaracteristiques as number]
        })
      );
    } else {
      actions.push(
        SocieteCaracteristiqueGeneratedActions.upsertManySocieteCaracteristiques({
          societeCaracteristiques: (ids.societeCaracteristiques as number[]).map(
            (idSocieteCaracteristique: number) => ({
              idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
              idSocieteCaracteristique
            })
          ) as SocieteCaracteristique[]
        })
      );
      actions.push(
        CaracteristiqueTemplateGeneratedActions.addManySocieteCaracteristiqueSuccess({
          idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
          idSocieteCaracteristiques: ids.societeCaracteristiques as number[]
        })
      );
    }
  }

  if (ids?.caracteristiqueChoixTemplates) {
    if (!Array.isArray(ids.caracteristiqueChoixTemplates)) {
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.upsertOneCaracteristiqueChoixTemplate({
          caracteristiqueChoixTemplate: {
            idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
            idCaracteristiqueChoixTemplate: ids.caracteristiqueChoixTemplates as number
          } as CaracteristiqueChoixTemplate
        })
      );
      actions.push(
        CaracteristiqueTemplateGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
          idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
          idCaracteristiqueChoixTemplates: [ids.caracteristiqueChoixTemplates as number]
        })
      );
    } else {
      actions.push(
        CaracteristiqueChoixTemplateGeneratedActions.upsertManyCaracteristiqueChoixTemplates({
          caracteristiqueChoixTemplates: (ids.caracteristiqueChoixTemplates as number[]).map(
            (idCaracteristiqueChoixTemplate: number) => ({
              idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
              idCaracteristiqueChoixTemplate
            })
          ) as CaracteristiqueChoixTemplate[]
        })
      );
      actions.push(
        CaracteristiqueTemplateGeneratedActions.addManyCaracteristiqueChoixTemplateSuccess({
          idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate,
          idCaracteristiqueChoixTemplates: ids.caracteristiqueChoixTemplates as number[]
        })
      );
    }
  }

  return actions;
}

export function getDefaultDeleteCaracteristiqueTemplateActions(caracteristiqueTemplate: CaracteristiqueTemplateEntityState): Action[] {
  const actions: Action[] = [CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplateSuccess({ idCaracteristiqueTemplate: caracteristiqueTemplate.idCaracteristiqueTemplate })];

  if (caracteristiqueTemplate.composantTemplate) {
    actions.push(
      ComposantTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess({
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate],
        idComposantTemplates: [caracteristiqueTemplate.composantTemplate as number]
      })
    );
  }

  if (caracteristiqueTemplate.patrimoineHierarchieTemplate) {
    actions.push(
      PatrimoineHierarchieTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess({
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate],
        idPatrimoineHierarchieTemplates: [caracteristiqueTemplate.patrimoineHierarchieTemplate as number]
      })
    );
  }

  if (caracteristiqueTemplate.societeCaracteristiques) {
    actions.push(
      SocieteCaracteristiqueGeneratedActions.deleteManyCaracteristiqueTemplateSuccess({
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate],
        idSocieteCaracteristiques: caracteristiqueTemplate.societeCaracteristiques as number[]
      })
    );
  }

  if (caracteristiqueTemplate.caracteristiqueChoixTemplates) {
    actions.push(
      CaracteristiqueChoixTemplateGeneratedActions.deleteManyCaracteristiqueTemplateSuccess({
        idCaracteristiqueTemplates: [caracteristiqueTemplate.idCaracteristiqueTemplate],
        idCaracteristiqueChoixTemplates: caracteristiqueTemplate.caracteristiqueChoixTemplates as number[]
      })
    );
  }

  return actions;
}

export class GeneratedCaracteristiqueTemplateEffects {
  constructor(
    protected actions$: Actions,
    protected caracteristiqueTemplateApiService: CaracteristiqueTemplateApiService,
    protected store$: Store<AppState>
  ) {}

  getManyCaracteristiqueTemplates$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueTemplateGeneratedActions.getManyCaracteristiqueTemplates),
      switchMap(({ params }) =>
        this.caracteristiqueTemplateApiService.getCaracteristiqueTemplates(params).pipe(
          map((caracteristiqueTemplates: CaracteristiqueTemplate[]) => {
            return CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert({ caracteristiqueTemplates });
          }),
          catchError(error => of(CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure({ error })))
        )
      )
    );
  });

  upsertOneCaracteristiqueTemplate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueTemplateGeneratedActions.upsertOneCaracteristiqueTemplate),
      concatMap(
        ({
          caracteristiqueTemplate,
          ids
        }: {
          caracteristiqueTemplate: Partial<CaracteristiqueTemplate>;
          ids?: CaracteristiqueTemplateRelationsIds;
        }) => {
          if (caracteristiqueTemplate.idCaracteristiqueTemplate) {
            return this.caracteristiqueTemplateApiService.updateCaracteristiqueTemplate(caracteristiqueTemplate).pipe(
              map((caracteristiqueTemplateReturned: CaracteristiqueTemplate) => {
                return CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert({ caracteristiqueTemplates: [caracteristiqueTemplateReturned] });
              }),
              catchError(error => of(CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure({ error })))
            );
          } else {
            return this.caracteristiqueTemplateApiService.addCaracteristiqueTemplate(caracteristiqueTemplate).pipe(
              mergeMap((caracteristiqueTemplateReturned: CaracteristiqueTemplate) => getDefaultAddCaracteristiqueTemplateActions(caracteristiqueTemplateReturned, ids)),
              catchError(error => of(CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure({ error })))
            );
          }
        }
      )
    );
  });

  deleteOneCaracteristiqueTemplate$ = createEffect(() => {
    const selectCaracteristiqueTemplateState$ = this.store$.select(CaracteristiqueTemplateSelectors.selectCaracteristiqueTemplateState);
    return this.actions$.pipe(
      ofType(CaracteristiqueTemplateGeneratedActions.deleteOneCaracteristiqueTemplate),
      withLatestFrom(selectCaracteristiqueTemplateState$),
      concatMap(([{ idCaracteristiqueTemplate }, state]) =>
        this.caracteristiqueTemplateApiService.deleteCaracteristiqueTemplate(idCaracteristiqueTemplate).pipe(
          mergeMap(_success => getDefaultDeleteCaracteristiqueTemplateActions(state.entities[idCaracteristiqueTemplate] as CaracteristiqueTemplateEntityState)),
          catchError(error => of(CaracteristiqueTemplateGeneratedActions.caracteristiqueTemplatesFailure({ error })))
        )
      )
    );
  });

  normalizeManyCaracteristiqueTemplatesAfterUpsert$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CaracteristiqueTemplateGeneratedActions.normalizeManyCaracteristiqueTemplatesAfterUpsert),
      concatMap(({ caracteristiqueTemplates }) => {
        const actions: Action[] = getActionsToNormalizeCaracteristiqueTemplate(caracteristiqueTemplates, StoreActionType.upsert);
        return [getMultiAction(actions, '[CaracteristiqueTemplate] Normalization After Upsert Success')];
      })
    );
  });
}
