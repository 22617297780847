import { Injectable } from '@angular/core';
import {
  AssertEmptyComposantAttenduInterface,
  Campagne,
  CampagneUser,
  CampaignInfos,
  Composant,
  ComposantAttendu,
  ComposantEntityState,
  Patrimoine,
  RefetchAvancement,
  Valeur,
  ValeurFichierEntityState
} from '@get/api-interfaces';
import {
  CampagneActions,
  CampagneGeneratedActions,
  CampagneUserGeneratedActions,
  ComposantAttenduGeneratedActions,
  ComposantGeneratedActions,
  EspaceGeneratedActions,
  PatrimoineGeneratedActions,
  ValeurFichierGeneratedActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { CampagneApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { GeneratedCampagneEffects } from './campagne-generated.effects';
import { getDefaultAddComposantActions, getDefaultDeleteComposantActions } from './composant-generated.effects';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';
import { getActionsToNormalizeComposant } from '@get/store/configs/normalization';
import { StoreActionType } from '@enums';
import { ComposantSelectors, ValeurFichierSelectors } from '@get/store/selectors';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { getDefaultDeleteValeurFichierActions } from './valeur-fichier-generated.effects';
import { transformArrayToObject } from '@utils';

@Injectable()
export class CampagneEffects extends GeneratedCampagneEffects {
  constructor(actions$: Actions, campagneApiService: CampagneApiService, store$: Store<AppState>) {
    super(actions$, campagneApiService, store$);
  }

  getOneCampagnePatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.getOneCampagnePatrimoines),
      switchMap(idCampagne =>
        this.campagneApiService.getCampagnePatrimoines(idCampagne).pipe(
          map((campagne: Campagne) => {
            return CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert({ campagnes: [campagne] });
          }),
          catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
        )
      )
    );
  });

  sendResponsibleMail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.sendResponsibleMail),
      switchMap((params: { emails: string[]; campaignInfos: CampaignInfos; launch: boolean }) => {
        return this.campagneApiService
          .sendResponsibleMail({ emails: params.emails, campaignInfos: params.campaignInfos, launch: params.launch })
          .pipe(
            map((campagneUsers: CampagneUser[]) => {
              return CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert({ campagneUsers });
            }),
            catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
          );
      })
    );
  });

  refetchAvancement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.refetchAvancement),
      switchMap((ids: RefetchAvancement) => {
        return this.campagneApiService.refetchAvancement(ids).pipe(
          map((patrimoines: Patrimoine[]) => {
            return PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines });
          }),
          catchError(error => of(CampagneGeneratedActions.campagnesFailure({ error })))
        );
      })
    );
  });

  createManyComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.createManyComposants),
      concatMap(({ composants }: { composants: Partial<Composant>[] }) => {
        return this.campagneApiService.createManyComposants(composants).pipe(
          mergeMap((composantReturned: Composant[]) => {
            return composantReturned
              .map(cmp =>
                getDefaultAddComposantActions(cmp, { espace: cmp.idEspace, societeComposant: cmp.idSocieteComposant })
              )
              .flat();
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  upsertOneComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertOneComposant),
      concatMap(({ composant, ids }: { composant: Partial<Composant>; ids?: ComposantRelationsIds }) => {
        if (composant.idComposant) {
          return this.campagneApiService.updateOneComposant(composant).pipe(
            map((composantReturned: Composant) => {
              return ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [composantReturned] });
            }),
            catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
          );
        } else {
          return this.campagneApiService.createOneComposant(composant).pipe(
            mergeMap((composantReturned: Composant) => getDefaultAddComposantActions(composantReturned, ids)),
            catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
          );
        }
      })
    );
  });

  upsertManyComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertManyComposant),
      concatMap(
        ({ composants, oldComposants }: { composants: Partial<Composant>[]; oldComposants: Partial<Composant>[] }) => {
          return this.campagneApiService.updateManyComposant(composants).pipe(
            switchMap((composantsReturned: Composant[]) => {
              const actions: Action[] = getActionsToNormalizeComposant(composantsReturned, StoreActionType.upsert);
              const composantsDict = transformArrayToObject(oldComposants, { key: 'idComposant' });
              for (let i = 0; i < composantsReturned?.length; i++) {
                if (composantsDict[composantsReturned[i].idComposant]?.idEspace !== composantsReturned[i].idEspace) {
                  actions.push(
                    ComposantGeneratedActions.addEspaceSuccess({
                      idComposant: composantsReturned[i].idComposant,
                      idEspace: composantsReturned[i].idEspace
                    })
                  );
                  actions.push(
                    EspaceGeneratedActions.addManyComposantSuccess({
                      idEspace: composantsReturned[i].idEspace,
                      idComposants: [composantsReturned[i].idComposant]
                    })
                  );
                  actions.push(
                    EspaceGeneratedActions.deleteManyComposantSuccess({
                      idComposants: [composantsReturned[i].idComposant],
                      idEspaces: [composantsDict[composantsReturned[i].idComposant]?.idEspace]
                    })
                  );
                }
              }
              return actions;
            }),
            catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
          );
        }
      )
    );
  });

  upsertOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertOneValeur),
      concatMap(({ valeur }: { valeur: Partial<Valeur> }) => {
        return this.campagneApiService.updateValeur(valeur).pipe(
          map((valeurReturned: Valeur) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeurReturned] });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        );
      })
    );
  });

  moveOneComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.moveOneComposant),
      concatMap(({ composant, oldIdEspace }: { composant: Partial<Composant>; oldIdEspace: number }) => {
        return this.campagneApiService.moveComposant(composant).pipe(
          switchMap((composantReturned: { composant: Composant; composantAttendus: ComposantAttendu[] }) => {
            const actions: Action[] = getActionsToNormalizeComposant(
              [composantReturned.composant],
              StoreActionType.upsert
            );
            if (oldIdEspace !== composantReturned.composant.idEspace) {
              actions.push(
                ComposantGeneratedActions.addEspaceSuccess({
                  idComposant: composantReturned.composant.idComposant,
                  idEspace: composantReturned.composant.idEspace
                })
              );
              actions.push(
                EspaceGeneratedActions.addManyComposantSuccess({
                  idEspace: composantReturned.composant.idEspace,
                  idComposants: [composantReturned.composant.idComposant]
                })
              );
              actions.push(
                EspaceGeneratedActions.deleteManyComposantSuccess({
                  idComposants: [composantReturned.composant.idComposant],
                  idEspaces: [oldIdEspace]
                })
              );
            }
            // TODO: trouver un moyen d'utiliser le getMultiActions
            return actions.concat(
              ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                composantAttendus: composantReturned.composantAttendus
              })
            );
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  duplicateOneComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.duplicateOneComposant),
      concatMap(({ idComposant, ids, params }) => {
        return this.campagneApiService.duplicateComposant(idComposant, params).pipe(
          mergeMap((composantReturned: Composant) => getDefaultAddComposantActions(composantReturned, ids)),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  duplicateAndMoveOneComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.duplicateAndMoveOneComposant),
      concatMap(({ body, ids, params }) => {
        return this.campagneApiService.duplicateAndMoveComposant(body, params).pipe(
          mergeMap((composantsReturned: Composant[]) => {
            const actions: Action[] = [];
            for (let i = 0; i < composantsReturned?.length; i++) {
              actions.push(
                ...getDefaultAddComposantActions(composantsReturned[i], {
                  espace: composantsReturned[i].idEspace
                } as ComposantRelationsIds)
              );
            }
            return [
              getMultiAction(actions),
              ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: composantsReturned })
            ];
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  upsertOneComposantModifyEspace$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertOneComposantModifyEspace),
      concatMap(({ composant, oldIdEspace }: { composant: Partial<Composant>; oldIdEspace: number }) => {
        return this.campagneApiService.updateOneComposant(composant).pipe(
          switchMap((composantReturned: Composant) => {
            const actions: Action[] = getActionsToNormalizeComposant([composantReturned], StoreActionType.upsert);
            if (oldIdEspace !== composantReturned.idEspace) {
              actions.push(
                ComposantGeneratedActions.addEspaceSuccess({
                  idComposant: composantReturned.idComposant,
                  idEspace: composantReturned.idEspace
                })
              );
              actions.push(
                EspaceGeneratedActions.addManyComposantSuccess({
                  idEspace: composantReturned.idEspace,
                  idComposants: [composantReturned.idComposant]
                })
              );
              actions.push(
                EspaceGeneratedActions.deleteManyComposantSuccess({
                  idComposants: [composantReturned.idComposant],
                  idEspaces: [oldIdEspace]
                })
              );
            }
            return actions;
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        );
      })
    );
  });

  upsertOneValeurReturnComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertOneValeurAndReturnComposant),
      concatMap(({ valeur, params }) => {
        return this.campagneApiService.updateValeurReturnComposant(valeur, params).pipe(
          mergeMap(
            ({ composant, composantAttendus }: { composant: Composant; composantAttendus: ComposantAttendu[] }) => {
              return [
                ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants: [composant] }),
                ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                  composantAttendus: composantAttendus
                })
              ];
            }
          ),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        );
      })
    );
  });

  upsertManyValeursReturnComposants$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.upsertManyValeursAndReturnComposants),
      concatMap(({ valeurs, params }) => {
        return this.campagneApiService.updateValeursReturnComposants(valeurs, params).pipe(
          mergeMap(
            ({ composants, composantAttendus }: { composants: Composant[]; composantAttendus: ComposantAttendu[] }) => {
              return [
                ComposantGeneratedActions.normalizeManyComposantsAfterUpsert({ composants }),
                ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                  composantAttendus: composantAttendus
                })
              ];
            }
          ),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        );
      })
    );
  });

  assertEmptyComposantAttendu$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.assertEmptyComposantAttendu),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
        return this.campagneApiService.assertEmptyComposantAttendu(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  assertEmptyTree$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CampagneActions.assertEmptyTree),
      concatMap(({ params }: { params: AssertEmptyComposantAttenduInterface }) => {
        return this.campagneApiService.assertEmptyTree(params).pipe(
          map((composantAttenduReturned: ComposantAttendu[]) => {
            return ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
              composantAttendus: composantAttenduReturned
            });
          }),
          catchError(error => of(ComposantAttenduGeneratedActions.composantAttendusFailure({ error })))
        );
      })
    );
  });

  deleteOneComposantWithComposantAttendu$ = createEffect(() => {
    const selectComposantState$ = this.store$.select(ComposantSelectors.selectComposantState);
    return this.actions$.pipe(
      ofType(CampagneActions.deleteOneComposantWithComposantAttendu),
      withLatestFrom(selectComposantState$),
      concatMap(([{ idComposant }, state]) =>
        this.campagneApiService.deleteComposantWithComposantAttendu(idComposant).pipe(
          mergeMap((success: { affectedRows: number; composantAttendus: ComposantAttendu[] }) => {
            return [
              getMultiAction(
                getDefaultDeleteComposantActions(state.entities[idComposant] as ComposantEntityState),
                CampagneActions.deleteOneComposantWithComposantAttendu.type
              ),
              ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert({
                composantAttendus: success.composantAttendus
              })
            ];
          }),
          catchError(error => of(ComposantGeneratedActions.composantsFailure({ error })))
        )
      )
    );
  });

  deleteOneValeurFichier$ = createEffect(() => {
    const selectValeurFichierState$ = this.store$.select(ValeurFichierSelectors.selectValeurFichierState);
    return this.actions$.pipe(
      ofType(CampagneActions.deleteOneValeurFichier),
      withLatestFrom(selectValeurFichierState$),
      concatMap(([{ idValeurFichier }, state]) =>
        this.campagneApiService.deleteValeurFichier(idValeurFichier).pipe(
          mergeMap(_success =>
            getDefaultDeleteValeurFichierActions(state.entities[idValeurFichier] as ValeurFichierEntityState)
          ),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        )
      )
    );
  });
}
