import { Injectable } from '@angular/core';
import { CampagneUserTypeEnum } from '@enums';
import {
  Campagne,
  CampagneUser,
  CampaignInfos,
  Patrimoine,
  SocieteCaracteristique,
  SocietePatrimoineHierarchie
} from '@get/api-interfaces';
import { combineLatest, firstValueFrom, map, Observable, ReplaySubject } from 'rxjs';
import { CampagneService } from '@get/store/services';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {
  private infos$: ReplaySubject<CampaignInfos | null> = new ReplaySubject<CampaignInfos | null>(1);
  private caracteristiques$: ReplaySubject<SocieteCaracteristique[]> = new ReplaySubject<SocieteCaracteristique[]>(1);
  private patrimoines$: ReplaySubject<Patrimoine[] | null> = new ReplaySubject<Patrimoine[] | null>(1);
  private patrimoineHierarchies$: ReplaySubject<SocietePatrimoineHierarchie | null> =
    new ReplaySubject<SocietePatrimoineHierarchie | null>(1);
  private responsables$: ReplaySubject<CampagneUser[] | null> = new ReplaySubject<CampagneUser[] | null>(1);

  private infosCompleted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private caracteristiquesCompleted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private patrimoinesCompleted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private responsablesCompleted$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private refetched = false;
  private campaignUpdated = false;

  constructor(private campagneService: CampagneService) {
    this.infos$.next(null);
    this.infosCompleted$.next(false);
    this.caracteristiquesCompleted$.next(false);
    this.patrimoinesCompleted$.next(false);
    this.responsablesCompleted$.next(false);
  }

  public forceRefetched(refetched: boolean): void {
    this.refetched = refetched;
  }

  public resetCampagne() {
    this.infos$.next({} as CampaignInfos);
    this.caracteristiques$.next([]);
    this.patrimoines$.next(null);
    this.patrimoineHierarchies$.next(null);
    this.responsables$.next(null);
    this.refetched = false;
    this.campaignUpdated = false;
  }

  public updateCampagne(campagne: Campagne): void {
    if (!this.refetched) {
      this.infos$.next({
        idCampagne: campagne.idCampagne,
        libelle: campagne.libelle,
        commentaire: campagne.commentaire,
        idOrganisateur:
          campagne.campagneUsers?.find(user => user.campagneUserType === CampagneUserTypeEnum.organisateur)?.idUser ??
          null,
        dateDebut: campagne.dateDebut,
        dateFin: campagne.dateFin
      });
      this.caracteristiques$.next(
        campagne.campagneSocieteCaracteristiques?.map(
          campagneSocieteCaracteristique => campagneSocieteCaracteristique.societeCaracteristique
        )
      );
      this.patrimoines$.next(campagne.campagnePatrimoines?.map(campagnePatrimoine => campagnePatrimoine.patrimoine));
      this.responsables$.next(
        campagne.campagneUsers.filter(user => user.campagneUserType === CampagneUserTypeEnum.responsable)
      );
    }
    this.refetched = false;
  }

  public setInfosCompleted(completed: boolean): void {
    this.infosCompleted$.next(completed);
  }

  public setCaracteristiquesCompleted(completed: boolean): void {
    this.caracteristiquesCompleted$.next(completed);
  }

  public setPatrimoinesCompleted(completed: boolean): void {
    this.patrimoinesCompleted$.next(completed);
  }

  public setResponsablesCompleted(completed: boolean): void {
    this.responsablesCompleted$.next(completed);
  }

  public getAllCompleted(): Observable<boolean> {
    return combineLatest([this.infosCompleted$, this.caracteristiquesCompleted$, this.patrimoinesCompleted$]).pipe(
      map(([...all]) => all.every(completed => completed))
    );
  }

  public updateSelectedCaracteristics(caracteristics: SocieteCaracteristique[]): void {
    this.caracteristiques$.next(caracteristics);
  }

  public getSelectedCaracteristics(): Observable<SocieteCaracteristique[]> {
    return this.caracteristiques$.asObservable();
  }

  public updateSelectedInfos({
    idCampagne,
    libelle,
    commentaire,
    idOrganisateur: organisateur,
    dateDebut,
    dateFin
  }: CampaignInfos): void {
    this.infos$.next({ idCampagne, libelle, commentaire, idOrganisateur: organisateur, dateDebut, dateFin });
  }

  public getSelectedInfos(): Observable<CampaignInfos | null> {
    return this.infos$.asObservable();
  }

  public updateSelectedPatrimoines(patrimoines: Patrimoine[] | null): void {
    this.patrimoines$.next(patrimoines);
  }

  public getSelectedPatrimoines(): Observable<Patrimoine[] | null> {
    return this.patrimoines$.asObservable();
  }

  public updateSelectedResponsables(responsables: CampagneUser[] | null): void {
    this.responsables$.next(responsables);
  }

  public getSelectedResponsables(): Observable<CampagneUser[] | null> {
    return this.responsables$.asObservable();
  }

  public getRefetched(): boolean {
    return this.refetched;
  }

  public getCampaignUpdated(): boolean {
    return this.campaignUpdated;
  }

  public setCampaignUpdated(updated: boolean): void {
    this.campaignUpdated = updated;
  }

  public async refetchAvancement(): Promise<void> {
    const caracteristiques = await firstValueFrom(this.caracteristiques$);
    const patrimoines = await firstValueFrom(this.patrimoines$);
    const responsables = await firstValueFrom(this.responsables$);
    const idsComposant = caracteristiques
      .map(el => el.idSocieteComposant)
      .filter((curr, idx, arr) => arr.indexOf(curr) === idx);
    const idsPatrimoine = patrimoines?.map(el => el.idPatrimoine) || [];
    const idsResponsable = responsables?.map(el => el.idUser) || [];
    this.refetched = true;
    this.campagneService.refetchAvancement({ idsComposant, idsPatrimoine, idsResponsable });
  }
}
