import { GridError, GridErrorEntityState, ProgComposantSousGroupe } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { GridErrorProgComposantSousGroupeState } from '@get/store/states';
import { SelectSchema, Selector, SelectorModel, getRelationSelectors } from '@get/utils';
import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { mergeProgComposantSousGroupeAndGridErrors } from '@utils';
import { selectAllProgComposantSousGroupes } from './prog-composant-sous-groupe-generated.selectors';

export const gridErrorRelations: string[] = [];

export const { selectEntities, selectAll } = GridErrorProgComposantSousGroupeState.adapter.getSelectors();

export const selectGridErrorState = createFeatureSelector<GridErrorProgComposantSousGroupeState.IState>(
  GridErrorProgComposantSousGroupeState.gridErrorFeatureKey
);

export const selectIsLoadedGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorProgComposantSousGroupeState.IState) => state.isLoaded
);

export const selectIsLoadingGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorProgComposantSousGroupeState.IState) => state.isLoading
);

export const selectIsReadyGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorProgComposantSousGroupeState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedGridError = createSelector(
  selectGridErrorState,
  (state: GridErrorProgComposantSousGroupeState.IState) => state.isLoaded && !state.isLoading
);

// tslint:disable-next-line: variable-name
export const GridErrorModel: SelectorModel = {
  name: 'gridErrors',
  getSelector: selectAllGridErrorsDictionary,
  isReady: selectIsReadyGridError
};

export const selectGridErrorsEntities = createSelector(selectGridErrorState, selectEntities);

export const selectGridErrorsArray = createSelector(selectGridErrorState, selectAll);

export const selectIdGridErrorsActive = createSelector(
  selectGridErrorState,
  (state: GridErrorProgComposantSousGroupeState.IState) => state.actives
);

const gridErrorsInObject = (gridErrors: Dictionary<GridErrorEntityState>) => ({ gridErrors });

const selectGridErrorsEntitiesDictionary = createSelector(selectGridErrorsEntities, gridErrorsInObject);

const selectAllGridErrorsObject = createSelector(selectGridErrorsEntities, gridErrors => {
  return hydrateAll({ gridErrors });
});

const selectOneGridErrorDictionary = (idGridError: number) =>
  createSelector(selectGridErrorsEntities, gridErrors => {
    return { gridErrors: { [idGridError]: gridErrors[idGridError] } };
  });

const selectOneGridErrorDictionaryWithoutChild = (idGridError: number) =>
  createSelector(selectGridErrorsEntities, gridErrors => {
    return { gridErrorProgComposantSousGroupe: gridErrors[idGridError] };
  });

const selectActiveGridErrorsEntities = createSelector(
  selectIdGridErrorsActive,
  selectGridErrorsEntities,
  (actives: number[], gridErrors: Dictionary<GridErrorEntityState>) => getGridErrorsFromActives(actives, gridErrors)
);

function getGridErrorsFromActives(
  actives: number[],
  gridErrors: Dictionary<GridErrorEntityState>
): Dictionary<GridErrorEntityState> {
  return actives.reduce((acc, idActive) => {
    if (gridErrors[idActive]) {
      acc[idActive] = gridErrors[idActive];
    }
    return acc;
  }, {} as Dictionary<GridErrorEntityState>);
}

const selectAllGridErrorsSelectors: Dictionary<Selector> = {};
export function selectAllGridErrors(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<GridError>(
      schema,
      selectAllGridErrorsSelectors,
      selectGridErrorsEntitiesDictionary,
      getRelationSelectors,
      gridErrorRelations,
      hydrateAll,
      'gridErrorProgComposantSousGroupe'
    );
  } else {
    return selectAllGridErrorsObject;
  }
}

export function selectAllGridErrorsDictionary(schema: SelectSchema = {}, customKey = 'gridErrors'): Selector {
  return createSelector(selectAllGridErrors(schema), result => {
    const res = { [customKey]: {} as Dictionary<GridErrorEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.gridErrors.length; i++) {
      res[customKey][result.gridErrors[i].idGridError] = result.gridErrors[i];
    }
    return res;
  });
}

export function selectOneGridError(schema: SelectSchema = {}, idGridError: number): Selector {
  if (schema.include) {
    const selectors: Selector[] = [selectOneGridErrorDictionary(idGridError)];
    selectors.push(...getRelationSelectors(schema, gridErrorRelations, 'gridErrorProgComposantSousGroupe'));
    return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneGridErrorDictionaryWithoutChild(idGridError);
  }
}

export function selectActiveGridErrors(schema: SelectSchema = {}): Selector {
  const selectors: Selector[] = [createSelector(selectActiveGridErrorsEntities, gridErrors => ({ gridErrors }))];
  selectors.push(...getRelationSelectors(schema, gridErrorRelations, 'gridErrorProgComposantSousGroupe'));
  return (createSelector as any)(...selectors, hydrateAll);
}

interface hydrateArgs {
  gridErrors: Dictionary<GridErrorEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { gridErrors: (GridError | null)[] } {
  const { gridErrors } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    gridErrors: Object.keys(gridErrors).map(idGridError => hydrate(gridErrors[idGridError] as GridErrorEntityState))
  };
}

function hydrateOne(...args: hydrateArgs[]): { gridErrorProgComposantSousGroupe: GridErrorEntityState | null } {
  const { gridErrors } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const gridErrorProgComposantSousGroupe = Object.values(gridErrors)[0];
  return { gridErrorProgComposantSousGroupe: hydrate(gridErrorProgComposantSousGroupe as GridErrorEntityState) };
}

function hydrate(gridErrorProgComposantSousGroupe: GridErrorEntityState): GridError | null {
  if (!gridErrorProgComposantSousGroupe) {
    return null;
  }

  const gridErrorHydrated: GridErrorEntityState = { ...gridErrorProgComposantSousGroupe };

  return gridErrorHydrated as GridError;
}

export function selectProgComposantSousGroupeAndGridErrors(schema: SelectSchema = {}) {
  return createSelector(
    selectAllProgComposantSousGroupes(schema),
    selectAllGridErrors({}),
    (
      selectedProgComposantSousGroupes: { progComposantSousGroupes: ProgComposantSousGroupe[] },
      selectedGridErrors: { gridErrors: GridError[] }
    ) => {
      return mergeProgComposantSousGroupeAndGridErrors(
        selectedProgComposantSousGroupes.progComposantSousGroupes,
        selectedGridErrors.gridErrors
      );
    }
  );
}
