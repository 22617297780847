import { Injectable } from '@angular/core';
import {
  AnalyzePatrimoineElement,
  Fichier,
  Patrimoine,
  PatrimoineEntityState,
  UpdatePatrimoineRattachement,
  Valeur,
  ValeurFichierEntityState
} from '@get/api-interfaces';
import {
  FichierGeneratedActions,
  PatrimoineActions,
  PatrimoineGeneratedActions,
  ValeurFichierGeneratedActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { PatrimoineApiService } from '@get/store/api-services';
import { getMultiAction } from '@get/store/configs/batched-actions';
import { AppState } from '@get/store/configs/reducers';
import { PatrimoineSelectors, ValeurFichierSelectors } from '@get/store/selectors';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, map, mergeMap, of, switchMap, withLatestFrom } from 'rxjs';
import { GeneratedPatrimoineEffects, getDefaultDeletePatrimoineActions } from './patrimoine-generated.effects';
import { getDefaultDeleteValeurFichierActions } from './valeur-fichier-generated.effects';
import { Action } from '@ngrx/store';

@Injectable()
export class PatrimoineEffects extends GeneratedPatrimoineEffects {
  constructor(actions$: Actions, patrimoineApiService: PatrimoineApiService, store$: Store<AppState>) {
    super(actions$, patrimoineApiService, store$);
  }

  getOnePatrimoineComplete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getOnePatrimoineComplete),
      switchMap(params =>
        this.patrimoineApiService.getOnePatrimoineComplete(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getOnePatrimoineWithComposantValeurs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getOnePatrimoineWithComposantValeurs),
      switchMap(params =>
        this.patrimoineApiService.getOnePatrimoineWithComposantValeurs(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getManyPatrimoinesWithValeurs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getManyPatrimoinesWithValeurs),
      switchMap(({ params }) =>
        this.patrimoineApiService.getManyPatrimoinesWithValeurs(params).pipe(
          map((patrimoines: AnalyzePatrimoineElement[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getHighestPatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getHighestPatrimoines),
      switchMap(({ params }) =>
        this.patrimoineApiService.getHighestPatrimoines(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getManyPatrimoinesRattachement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getManyPatrimoinesRattachement),
      switchMap(({ params }) =>
        this.patrimoineApiService.getPatrimoinesRattachement(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getManyPatrimoinesForUserRattachement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getManyPatrimoinesForUserRattachement),
      switchMap(({ params }) =>
        this.patrimoineApiService.getManyPatrimoinesForUserRattachement(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getDescendantsPatrimoine$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getDescendantsPatrimoine),
      switchMap(idPatrimoine =>
        this.patrimoineApiService.getDescendantsById(idPatrimoine).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOnePatrimoineRattachement$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.upsertOnePatrimoineRattachement),
      concatMap(({ params }: { params: UpdatePatrimoineRattachement }) =>
        this.patrimoineApiService.updatePatrimoineRattachement(params).pipe(
          map((patrimoineReturned: Patrimoine) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines: [patrimoineReturned] })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  upsertOneValeur$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.upsertOneValeur),
      concatMap(({ valeur }: { valeur: Partial<Valeur> }) => {
        return this.patrimoineApiService.updateValeur(valeur).pipe(
          map((valeurReturned: Valeur) => {
            return ValeurGeneratedActions.normalizeManyValeursAfterUpsert({ valeurs: [valeurReturned] });
          }),
          catchError(error => of(ValeurGeneratedActions.valeursFailure({ error })))
        );
      })
    );
  });

  getManyPatrimoinesProgress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getManyPatrimoinesProgress),
      switchMap(({ params }) =>
        this.patrimoineApiService.getPatrimoinesProgress(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getPatrimoinesForPerTypeComposant$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getPatrimoinesForPerTypeComposant),
      switchMap(({ params }) =>
        this.patrimoineApiService.getPatrimoinesForPerTypeComposant(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getManyPatrimoineAnalyze$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getManyPatrimoineAnalyze),
      switchMap(({ params }) =>
        this.patrimoineApiService.getManyPatrimoineAnalyze(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  getOnePatrimoineForCampagne$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.getOnePatrimoineForCampagne),
      switchMap(({ params }) =>
        this.patrimoineApiService.getOnePatrimoineForCampagne(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  fetchAnalyzeSyntheticPatrimoines$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.fetchAnalyzeSyntheticPatrimoines),
      switchMap(({ params }) =>
        this.patrimoineApiService.fetchAnalyzeSyntheticPatrimoines(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  fetchAdminPatrimoineList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(PatrimoineActions.fetchAdminPatrimoineList),
      switchMap(({ params }) =>
        this.patrimoineApiService.fetchAdminPatrimoineList(params).pipe(
          map((patrimoines: Patrimoine[]) =>
            PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines })
          ),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  deleteAllPatrimoines$ = createEffect(() => {
    const selectPatrimoineState$ = this.store$.select(PatrimoineSelectors.selectPatrimoineState);
    return this.actions$.pipe(
      ofType(PatrimoineActions.deleteAllPatrimoines),
      withLatestFrom(selectPatrimoineState$),
      concatMap(([{ idSociete }, state]) =>
        this.patrimoineApiService.deleteAllPatrimoines(idSociete).pipe(
          mergeMap(_success => [
            getMultiAction(
              Object.keys(state.entities)
                .map(id => getDefaultDeletePatrimoineActions(state.entities[id] as PatrimoineEntityState))
                .flat(),
              PatrimoineActions.deleteAllPatrimoinesSuccess.type
            ),
            PatrimoineActions.deleteManyPatrimoineSuccess()
          ]),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  deleteOneValeurFichier$ = createEffect(() => {
    const selectValeurFichierState$ = this.store$.select(ValeurFichierSelectors.selectValeurFichierState);
    return this.actions$.pipe(
      ofType(PatrimoineActions.deleteOneValeurFichier),
      withLatestFrom(selectValeurFichierState$),
      concatMap(([{ idValeurFichier }, state]) => {
        return this.patrimoineApiService.deleteValeurFichier(idValeurFichier).pipe(
          mergeMap((fichiersReturned: Fichier[]) => [
            getMultiAction(
              getDefaultDeleteValeurFichierActions(state.entities[idValeurFichier] as ValeurFichierEntityState)
            ),
            FichierGeneratedActions.normalizeManyFichiersAfterUpsert({ fichiers: fichiersReturned })
          ]),
          catchError(error => of(ValeurFichierGeneratedActions.valeurFichiersFailure({ error })))
        );
      })
    );
  });

  deleteOnePatrimoineAndReturnAllDeletedPatrimoines$ = createEffect(() => {
    const selectPatrimoineState$ = this.store$.select(PatrimoineSelectors.selectPatrimoineState);
    return this.actions$.pipe(
      ofType(PatrimoineActions.deleteOnePatrimoineAndReturnAllDeletedPatrimoines),
      withLatestFrom(selectPatrimoineState$),
      concatMap(([{ idPatrimoine }, state]) =>
        this.patrimoineApiService.deleteOnePatrimoineAndReturnAllDeletedPatrimoines(idPatrimoine).pipe(
          mergeMap((success: { affectedRows: number; idsPatrimoine: number[] }) => {
            const actions: Action[] = [];
            for (let i = 0; i < success.idsPatrimoine?.length; i++) {
              actions.push(
                ...getDefaultDeletePatrimoineActions(state.entities[success.idsPatrimoine[i]] as PatrimoineEntityState)
              );
            }
            return [getMultiAction(actions)];
          }),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });

  deleteManyPatrimoinesByIds$ = createEffect(() => {
    const selectPatrimoineState$ = this.store$.select(PatrimoineSelectors.selectPatrimoineState);
    return this.actions$.pipe(
      ofType(PatrimoineActions.deleteManyPatrimoinesByIds),
      withLatestFrom(selectPatrimoineState$),
      concatMap(([{ idsPatrimoine }, state]) =>
        this.patrimoineApiService.deleteManyPatrimoinesByIds(idsPatrimoine).pipe(
          mergeMap((success: { idsPatrimoine: number[] }) => {
            const actions: Action[] = [];
            for (let i = 0; i < success.idsPatrimoine?.length; i++) {
              actions.push(
                ...getDefaultDeletePatrimoineActions(state.entities[success.idsPatrimoine[i]] as PatrimoineEntityState)
              );
            }
            const filteredActions: Action[] = actions.filter(
              action => action.type !== PatrimoineGeneratedActions.deleteOnePatrimoineSuccess.type
            );
            const allActions = (
              [PatrimoineActions.deleteManyPatrimoine({ idsPatrimoine: success.idsPatrimoine })] as Action[]
            ).concat(filteredActions);
            if (allActions?.length > 1) {
              return [getMultiAction(allActions), PatrimoineActions.deleteManyPatrimoineSuccess()];
            } else {
              return allActions.concat(PatrimoineActions.deleteManyPatrimoineSuccess());
            }
          }),
          catchError(error => of(PatrimoineGeneratedActions.patrimoinesFailure({ error })))
        )
      )
    );
  });
}
