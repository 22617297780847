export function joinStringArrayWithSeparator(props: string[], separator = ', '): string {
  return props?.filter(prop => !!prop)?.join(separator ?? ', ') || '';
}

export function transformToPlural(value: string): string {
  if (!value) {
    return value;
  }
  const conditions = [value.endsWith('s'), value.endsWith('x'), value.endsWith('z')];
  const isAlreadyPlural = conditions.some(condition => !!condition);
  if (isAlreadyPlural) {
    return value;
  }

  const doesTakeX = ['bijou', 'caillou', 'chou', 'genou', 'hibou', 'joujou', 'pou'];
  const doesNotTakeX = ['landau', 'sarrau', 'bleu', 'pneu', 'émeu'];
  const shouldEndWithX =
    value.endsWith('au') ||
    (value.endsWith('eu') && doesNotTakeX.every(el => el !== value)) ||
    doesTakeX.some(el => value === el);

  if (shouldEndWithX) {
    return value + 'x';
  }
  const exceptionsWithAil = ['bail', 'corail', 'émail', 'soupirail', 'travail', 'ventail', 'vitrail'];

  if (exceptionsWithAil.some(el => el === value)) {
    return value.replace(/ail$/, 'aux');
  }

  return value + 's';
}

export function compareStrings(a: string, b: string): number {
  return a?.localeCompare(b, 'fr', { numeric: true });
}

export function formatPhoneNumber(phoneNumber: string, separator = ' ', nbElementPerBatch = 2, nbBatch = 5): string {
  if (!phoneNumber) {
    return phoneNumber;
  }
  const splitted = [];
  for (let i = 0, j = 1; i < phoneNumber.length; i += nbElementPerBatch) {
    if (j >= nbBatch) {
      splitted.push(phoneNumber.substring(i, phoneNumber.length));
      break;
    }
    splitted.push(phoneNumber.substring(i, i + nbElementPerBatch));
    j++;
  }
  return splitted.join(separator ?? ' ');
}
