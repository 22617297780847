import { RepositoryService } from '@get/services/repository';
import { Composant } from '@get/api-interfaces';
import { Observable } from 'rxjs';

export class GeneratedComposantApiService {
  constructor(protected repo: RepositoryService) {}

  public addComposant(composant: Partial<Composant>): Observable<Composant> {
    return this.repo.create<Composant>('composant', composant);
  }

  public updateComposant(composant: Partial<Composant>): Observable<Composant> {
    return this.repo.update('composant', composant);
  }

  public deleteComposant(idComposant: number): Observable<number> {
    return this.repo.delete('composant/' + +idComposant);
  }
}
