import { Injectable } from '@angular/core';
import {
  AdminPatrimoineListQueryParams,
  AnalyzeGraphicDtoInterface,
  AnalyzeGraphicPatrimoine,
  AnalyzePatrimoineElement,
  AnalyzePerPatrimoineParams,
  AnalyzeSyntheticPatrimoinesQueryParams,
  AnalyzeSyntheticQueryParams,
  CountPatrimoineInterface,
  FetchPatrimoineForCampagne,
  Fichier,
  Patrimoine,
  PatrimoineProgFilteredInfos,
  PatrimoineRattachementFindOptions,
  ProgFilterDtoBase,
  ProgFilterInfos,
  ProgFilterPatrimoineDto,
  UpdatePatrimoineRattachement,
  Valeur
} from '@get/api-interfaces';
import {
  PatrimoineActions,
  PatrimoineGeneratedActions,
  ValeurFichierGeneratedActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { PatrimoineApiService } from '@get/store/api-services';
import { AppState } from '@get/store/configs/reducers';
import { PatrimoineSelectors } from '@get/store/selectors';
import { SelectSchema, catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { saveAs } from 'file-saver';
import { Observable, ReplaySubject, first, firstValueFrom, mapTo, switchMap, tap } from 'rxjs';
import { GeneratedPatrimoineService } from './patrimoine-generated.service';

@Injectable({
  providedIn: 'root'
})
export class PatrimoineService extends GeneratedPatrimoineService {
  constructor(store$: Store<AppState>, actions$: Actions, private readonly patrimoineApiService: PatrimoineApiService) {
    super(store$, actions$);
  }

  public getOnePatrimoineComplete(
    idPatrimoine: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getOnePatrimoineComplete({ idPatrimoine, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getOnePatrimoineWithComposantValeurs(
    idPatrimoine: number,
    params: any = {},
    completedRoute$?: ReplaySubject<void>
  ): void {
    this.patrimoineApiService
      .getOnePatrimoineWithComposantValeurs({ idPatrimoine, params })
      .pipe(
        first(),
        tap(patrimoines => {
          this.store$.dispatch(PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert({ patrimoines }));
          completedRoute$?.next();
        })
      )
      .subscribe();
  }

  public getManyPatrimoinesWithValeurs(
    params: any = {},
    getResult?: boolean
  ): void | Observable<AnalyzePatrimoineElement[]> {
    this.store$.dispatch(PatrimoineActions.getManyPatrimoinesWithValeurs({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getHighestPatrimoines(params: any = {}, getResult?: boolean): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getHighestPatrimoines({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getHighestPatrimoinesMassDuplication(params: any = {}): Promise<Patrimoine[]> {
    return this.patrimoineApiService.getHighestPatrimoinesMassDuplication(params);
  }

  public getCountPatrimoineHierarchie(params: AnalyzeGraphicDtoInterface): Promise<CountPatrimoineInterface[]> {
    return this.patrimoineApiService.getCountPatrimoineHierarchie(params);
  }

  public getCountPatrimoineCaracteristiques(params: AnalyzeGraphicDtoInterface): Promise<AnalyzeGraphicPatrimoine> {
    return this.patrimoineApiService.getCountPatrimoineCaracteristiques(params);
  }

  public getIdsPatrimoineFilteredForProg(params: ProgFilterPatrimoineDto): Promise<PatrimoineProgFilteredInfos> {
    return this.patrimoineApiService.getIdsPatrimoineFilteredForProg(params);
  }

  public getProgFilterInfos(params: ProgFilterDtoBase): Promise<ProgFilterInfos> {
    return this.patrimoineApiService.getProgFilterInfos(params);
  }

  public getManyPatrimoinesRattachement(
    params: PatrimoineRattachementFindOptions,
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getManyPatrimoinesRattachement({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getManyPatrimoinesForUserRattachement(params: any = {}, getResult?: boolean): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getManyPatrimoinesForUserRattachement({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getDescendantsPatrimoine(
    idPatrimoine: number,
    idSocietePatrimoineHierarchie: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(
      PatrimoineActions.getDescendantsPatrimoine({ idPatrimoine, idSocietePatrimoineHierarchie, params })
    );
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public selectAllPatrimoinesHighestAncestors(schema: SelectSchema): Observable<Patrimoine[]> {
    // TODO: Trouver une solution pour faire le schema ici et pas dans le component (GT-109)
    // const schema: SelectSchema = {
    //   include: [{ model: SocietePatrimoineHierarchieModel, include: [HierarchieAncetresModel] }]
    // };
    return this.store$.pipe(select(PatrimoineSelectors.selectAllPatrimoinesHighestAncestors(schema))).pipe(
      switchMap(({ patrimoines }: { patrimoines: Patrimoine[] }) => {
        return this.getReady(schema).pipe(mapTo(patrimoines));
      })
    );
  }

  public selectOnePatrimoineDescendants(
    idPatrimoine: number,
    idSocietePatrimoineHierarchie: number,
    schema: SelectSchema = {}
  ): Observable<Patrimoine> {
    return this.store$
      .pipe(
        select(PatrimoineSelectors.selectOnePatrimoineDescendants(schema, idPatrimoine, idSocietePatrimoineHierarchie))
      )
      .pipe(
        switchMap(({ patrimoines }: { patrimoines: Patrimoine }) => {
          return this.getReady(schema).pipe(mapTo(patrimoines));
        })
      );
  }

  public upsertOnePatrimoineRattachement(
    params: UpdatePatrimoineRattachement,
    getResult?: boolean
  ): void | Observable<Patrimoine> {
    this.store$.dispatch(PatrimoineActions.upsertOnePatrimoineRattachement({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure,
        true
      );
    }
  }

  public fetchAnalyzeSyntheticElements(params: AnalyzeSyntheticQueryParams): Promise<any[]> {
    return this.patrimoineApiService.fetchAnalyzeSyntheticElements(params);
  }

  public getPatrimoineReturnDescendants(params: {
    idPatrimoine: number;
    idSocietePatrimoineHierarchie: number;
  }): Promise<Patrimoine[]> {
    return this.patrimoineApiService.getPatrimoineReturnDescendants(params);
  }

  public fetchAnalyzeSyntheticPatrimoines(
    params: AnalyzeSyntheticPatrimoinesQueryParams,
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.fetchAnalyzeSyntheticPatrimoines({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public fetchAdminPatrimoineList(
    params: AdminPatrimoineListQueryParams,
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.fetchAdminPatrimoineList({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  // TODO: Check pour supprimer (actions + effects + apiservice)
  public getManyPatrimoinesProgress(params: any = {}, getResult?: boolean): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getManyPatrimoinesProgress({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getPatrimoinesForPerTypeComposant(params: any = {}, getResult?: boolean): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getPatrimoinesForPerTypeComposant({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getManyPatrimoineAnalyze(
    params: AnalyzePerPatrimoineParams,
    getResult?: boolean
  ): void | Observable<Patrimoine[]> {
    this.store$.dispatch(PatrimoineActions.getManyPatrimoineAnalyze({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public getOnePatrimoineForCampagne(
    params: FetchPatrimoineForCampagne,
    getResult?: boolean
  ): void | Observable<Patrimoine> {
    this.store$.dispatch(PatrimoineActions.getOnePatrimoineForCampagne({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        PatrimoineGeneratedActions.patrimoinesFailure,
        true
      );
    }
  }

  public uploadManyImages(
    files: File[],
    queryParams: { idOrganisation?: number; ordre?: number } = {}
  ): Observable<Fichier[]> {
    return this.patrimoineApiService.uploadManyImages(files, queryParams);
  }

  public uploadMany(files: File[], queryParams: { idOrganisation?: number } = {}): Observable<Fichier[]> {
    return this.patrimoineApiService.uploadMany(files, queryParams);
  }

  public downloadOneFichier(fichier: Fichier): void {
    this.patrimoineApiService
      .downloadOneFichier(fichier.idFichier)
      .pipe(tap(blob => saveAs(blob, fichier.originalName)))
      .subscribe();
  }

  public upsertOneValeur(valeur: Partial<Valeur>, getResult?: boolean): void | Observable<Valeur> {
    this.store$.dispatch(PatrimoineActions.upsertOneValeur({ valeur }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.normalizeManyValeursAfterUpsert,
        ValeurGeneratedActions.valeursFailure,
        true
      );
    }
  }

  public deleteAllPatrimoines(idSociete: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(PatrimoineActions.deleteAllPatrimoines({ idSociete }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineActions.deleteManyPatrimoineSuccess,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public deleteOneValeurFichier(idValeurFichier: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(PatrimoineActions.deleteOneValeurFichier({ idValeurFichier }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurFichierGeneratedActions.deleteOneValeurFichierSuccess,
        ValeurFichierGeneratedActions.valeurFichiersFailure
      );
    }
  }

  public deleteOnePatrimoineAndReturnAllDeletedPatrimoines(
    idPatrimoine: number,
    getResult?: boolean
  ): void | Observable<number> {
    this.store$.dispatch(PatrimoineActions.deleteOnePatrimoineAndReturnAllDeletedPatrimoines({ idPatrimoine }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.deleteOnePatrimoineSuccess,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public deleteManyPatrimoinesByIds(idsPatrimoine: number[], getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(PatrimoineActions.deleteManyPatrimoinesByIds({ idsPatrimoine }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineActions.deleteManyPatrimoineSuccess,
        PatrimoineGeneratedActions.patrimoinesFailure
      );
    }
  }

  public fetchMultiYearProgrammationWithoutStore(params: any = {}): Promise<Patrimoine[]> {
    return firstValueFrom(this.patrimoineApiService.fetchMultiYearProgrammation({ params }));
  }

  public fetchSingleYearProgrammationWithoutStore(params: any = {}): Promise<Patrimoine[]> {
    return firstValueFrom(this.patrimoineApiService.fetchSingleYearProgrammation({ params }));
  }

  public fetchFollowUpProgrammationWithoutStore(params: any = {}): Promise<Patrimoine[]> {
    return firstValueFrom(this.patrimoineApiService.fetchFollowUpProgrammation({ params }));
  }

  public fetchProgPatrimoinesTreeProgrammationWithoutStore(params: ProgFilterDtoBase): Promise<Patrimoine[]> {
    return firstValueFrom(this.patrimoineApiService.fetchProgPatrimoinesTreeProgrammation({ params }));
  }
}
