import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DataAssistedImport, Fichier } from '@get/api-interfaces';
import { environment } from '@get/environment';
import { RepositoryService } from '@get/services/repository';
import { EMPTY, Observable } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';
import { GeneratedFichierApiService } from './fichier-api-generated.service';
import { ImportTypeFileExampleEnum } from '@enums';

@Injectable({
  providedIn: 'root'
})
export class FichierApiService extends GeneratedFichierApiService {
  constructor(private readonly http: HttpClient, repo: RepositoryService) {
    super(repo);
  }

  public uploadMany(files: Array<File>, queryParams: { idOrganisation?: number } = {}): Observable<Array<Fichier>> {
    const formData = new FormData();
    const route = `${new URL(environment.apiUrl + '/import/files').href}${
      queryParams?.idOrganisation ? '?idOrganisation=' + queryParams.idOrganisation : ''
    }`;
    files.forEach(media => {
      formData.append('files', media, encodeURIComponent(media.name));
    });

    return this.http.post(route, formData).pipe(
      first(),
      map((res: any) => res.data),
      catchError(() => EMPTY)
    );
  }

  public importXlsx(idOrganisation: number, idSociete: number, formData: FormData) {
    return this.http
      .post(environment.apiUrl + `/import/xlsx?idOrganisation=${idOrganisation}&idSociete=${idSociete}`, formData)
      .pipe(first());
  }

  public uploadGeneratedFile(idOrganisation: number, idSociete: number, pathFile: string) {
    return this.http
      .post(
        environment.apiUrl +
          `/import-assisted/upload-generated-file?idOrganisation=${idOrganisation}&idSociete=${idSociete}`,
        { pathFile },
        { responseType: 'blob' }
      )
      .pipe(first());
  }

  public importTemplate(
    idOrganisation: number,
    idSociete: number,
    typeToImport: ImportTypeFileExampleEnum[],
    withAllDatas: boolean
  ): Observable<Blob> {
    return this.http
      .post(
        environment.apiUrl + `/import/template?idOrganisation=${idOrganisation}&idSociete=${idSociete}`,
        { typeToImport, withAllDatas },
        {
          responseType: 'blob'
        }
      )
      .pipe(first());
  }

  public assistedImport(
    idOrganisation: number,
    idSociete: number,
    data: DataAssistedImport,
    file: File
  ): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, encodeURIComponent(file.name));
    // on stringifie car formdata ne prend que des file ou des string (pas de json)
    formData.append('params', JSON.stringify(data));
    return this.http
      .post(environment.apiUrl + `/import-assisted?idOrganisation=${idOrganisation}&idSociete=${idSociete}`, formData)
      .pipe(first());
  }

  public generatePreviewFile(idFichier: number): Observable<Fichier> {
    return this.http
      .get<{ data: Fichier }>(environment.apiUrl + `/fichier/preview/file/${idFichier}`)
      .pipe(map(result => result.data));
  }

  public upsertManyFichiers(fichiers: Partial<Fichier>[]): Observable<Fichier[]> {
    return this.repo.update('fichier', fichiers);
  }
}
