function pSBCr(col, i, red, green, blue, alpha) {
  let n = col.length,
    x: any = {};
  if (n > 9) {
    ([red, green, blue, alpha] = col = col.split(',')), (n = col.length);
    if (n < 3 || n > 4) return null;
    (x.r = i(red[3] == 'a' ? red.slice(5) : red.slice(4))),
      (x.g = i(green)),
      (x.b = i(blue)),
      (x.a = alpha ? parseFloat(alpha) : -1);
  } else {
    if (n == 8 || n == 6 || n < 4) return null;
    if (n < 6) col = '#' + col[1] + col[1] + col[2] + col[2] + col[3] + col[3] + (n > 4 ? col[4] + col[4] : '');
    col = i(col.slice(1), 16);
    if (n == 9 || n == 5)
      (x.r = (col >> 24) & 255),
        (x.g = (col >> 16) & 255),
        (x.b = (col >> 8) & 255),
        (x.a = Math.round((col & 255) / 0.255) / 1000);
    else (x.r = col >> 16), (x.g = (col >> 8) & 255), (x.b = col & 255), (x.a = -1);
  }
  return x;
}

// color1OrConvert should be either a valid color or 'c' for conversion
export function pSBC(percentage, color0, color1OrConvert, linear) {
  let red,
    green,
    blue,
    P,
    f,
    t,
    h,
    i = parseInt,
    alpha = typeof color1OrConvert == 'string' ? 1 : 0;
  if (
    typeof percentage != 'number' ||
    percentage < -1 ||
    percentage > 1 ||
    typeof color0 != 'string' ||
    (color0[0] != 'r' && color0[0] != '#') ||
    (color1OrConvert && !alpha)
  )
    return null;
  (h = color0.length > 9),
    (h = alpha ? (color1OrConvert.length > 9 ? true : color1OrConvert == 'c' ? !h : false) : h),
    (f = pSBCr(color0, i, red, green, blue, alpha)),
    (P = percentage < 0),
    (t =
      color1OrConvert && color1OrConvert != 'c'
        ? pSBCr(color1OrConvert, i, red, green, blue, alpha)
        : P
        ? { r: 0, g: 0, b: 0, a: -1 }
        : { r: 255, g: 255, b: 255, a: -1 }),
    (percentage = P ? percentage * -1 : percentage),
    (P = 1 - percentage);
  if (!f || !t) return null;
  if (linear)
    (red = Math.round(P * f.r + percentage * t.r)),
      (green = Math.round(P * f.g + percentage * t.g)),
      (blue = Math.round(P * f.b + percentage * t.b));
  else
    (red = Math.round((P * f.r ** 2 + percentage * t.r ** 2) ** 0.5)),
      (green = Math.round((P * f.g ** 2 + percentage * t.g ** 2) ** 0.5)),
      (blue = Math.round((P * f.b ** 2 + percentage * t.b ** 2) ** 0.5));
  (alpha = f.a),
    (t = t.a),
    (f = alpha >= 0 || t >= 0),
    (alpha = f ? (alpha < 0 ? t : t < 0 ? alpha : alpha * P + t * percentage) : 0);
  if (h)
    return (
      'rgb' + (f ? 'a(' : '(') + red + ',' + green + ',' + blue + (f ? ',' + Math.round(alpha * 1000) / 1000 : '') + ')'
    );
  else
    return (
      '#' +
      (4294967296 + red * 16777216 + green * 65536 + blue * 256 + (f ? Math.round(alpha * 255) : 0))
        .toString(16)
        .slice(1, f ? undefined : -2)
    );
}
