export enum AdminPathEnum {
  users = 'users',
  processus = 'processus',
  societePatrimoineHierarchie = 'societe-patrimoine-hierarchie',
  societeEspaceFamilles = 'societe-espace-familles',
  societeEspaces = 'societe-espaces',
  composantGroupes = 'composant-groupes',
  societeComposantFamilles = 'societe-composants-familles',
  societeComposants = 'societe-composants',
  societeCaracteristiquesComposants = 'societe-caracteristique-composants',
  societeCaracteristiquesPatrimoines = 'societe-caracteristique-patrimoines',
  societeTerritoires = 'societe-territoires',
  societeTerritoiresPatrimoines = 'societe-territoires-patrimoines',
  societeProfils = 'societe-profils',
  societeUsersPatrimoines = 'societe-users-patrimoines',
  import = 'import',
  configuration = 'configuration-get-and-share',
  composantTemplate = 'composant-template',
  caracteristiqueComposantTemplate = 'caracteristique-composant-template',
  caracteristiquePatrimoineTemplate = 'caracteristique-patrimoine-template',
  patrimoineHierarchieTemplate = 'patrimoine-hierarchie-template',
  societeCreation = 'societe-creation',
  adminGlobal = 'admin-global',
  composants = 'composants',
  newsletter = 'newsletter',
  patrimoines = 'patrimoines',
  adminProgrammation = 'admin-programmation',
  progComposants = 'prog-composants',
  progComposantGroupes = 'prog-composant-groupes',
  progComposantSousGroupes = 'prog-composant-sous-groupes',
  progUnites = 'prog-unites',
  progScenarios = 'prog-scenarios',
  progBudgets = 'prog-budgets',
  progInterventionFamilles = 'prog-intervention-familles',
  progBiblioInterventions = 'prog-biblio-interventions',
  progSocieteCaracteristiques = 'prog-societe-caracteristiques',
  adminModuleAccess = 'admin-module-access',
  analyzeGlobal = 'analyze-global',
  progEtatTechniques = 'prog-etats-techniques'
}
