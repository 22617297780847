import { Dictionary } from '@ngrx/entity';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CaracteristiqueTemplate, CaracteristiqueTemplateEntityState } from '@get/api-interfaces';
import { SocieteCaracteristique, SocieteCaracteristiqueEntityState } from '@get/api-interfaces';
import { CaracteristiqueChoixTemplate, CaracteristiqueChoixTemplateEntityState } from '@get/api-interfaces';
import { ComposantTemplate, ComposantTemplateEntityState } from '@get/api-interfaces';
import { PatrimoineHierarchieTemplate, PatrimoineHierarchieTemplateEntityState } from '@get/api-interfaces';
import { findOrCreateSelector } from '@get/services/ngrx-helper';
import { CaracteristiqueTemplateState } from '@get/store/states';
import { getRelationSelectors, Selector, SelectSchema } from '@get/utils';

export const caracteristiqueTemplateRelations: string[] = ['societeCaracteristiques','caracteristiqueChoixTemplates','composantTemplates','patrimoineHierarchieTemplates',];

export const { selectEntities, selectAll } = CaracteristiqueTemplateState.adapter.getSelectors();

export const selectCaracteristiqueTemplateState = createFeatureSelector<CaracteristiqueTemplateState.IState>(CaracteristiqueTemplateState.caracteristiqueTemplateFeatureKey);

export const selectIsLoadedCaracteristiqueTemplate = createSelector(
  selectCaracteristiqueTemplateState,
  (state: CaracteristiqueTemplateState.IState) => state.isLoaded
);

export const selectIsLoadingCaracteristiqueTemplate = createSelector(
  selectCaracteristiqueTemplateState,
  (state: CaracteristiqueTemplateState.IState) => state.isLoading
);

export const selectIsReadyCaracteristiqueTemplate = createSelector(
  selectCaracteristiqueTemplateState,
  (state: CaracteristiqueTemplateState.IState) => !state.isLoading
);

export const selectIsReadyAndLoadedCaracteristiqueTemplate = createSelector(
  selectCaracteristiqueTemplateState,
  (state: CaracteristiqueTemplateState.IState) => state.isLoaded && !state.isLoading
);

export const selectCaracteristiqueTemplatesEntities = createSelector(selectCaracteristiqueTemplateState, selectEntities);

export const selectCaracteristiqueTemplatesArray = createSelector(selectCaracteristiqueTemplateState, selectAll);

const caracteristiqueTemplatesInObject = (caracteristiqueTemplates: Dictionary<CaracteristiqueTemplateEntityState>) => ({ caracteristiqueTemplates })

const selectCaracteristiqueTemplatesEntitiesDictionary = createSelector(selectCaracteristiqueTemplatesEntities, caracteristiqueTemplatesInObject);

const selectAllCaracteristiqueTemplatesObject = createSelector(selectCaracteristiqueTemplatesEntities, caracteristiqueTemplates => {
  return hydrateAll({ caracteristiqueTemplates });
});

const selectOneCaracteristiqueTemplateDictionary = (idCaracteristiqueTemplate : number) =>
  createSelector(selectCaracteristiqueTemplatesEntities, caracteristiqueTemplates => ({
    caracteristiqueTemplates: { [idCaracteristiqueTemplate]: caracteristiqueTemplates[idCaracteristiqueTemplate] }
  }));

const selectOneCaracteristiqueTemplateDictionaryWithoutChild = (idCaracteristiqueTemplate : number) =>
  createSelector(selectCaracteristiqueTemplatesEntities, caracteristiqueTemplates => ({
    caracteristiqueTemplate: caracteristiqueTemplates[idCaracteristiqueTemplate]
  }));

const selectAllCaracteristiqueTemplatesSelectors: Dictionary<Selector> = {};
export function selectAllCaracteristiqueTemplates(schema: SelectSchema = {}): Selector {
  if (schema.include) {
    return findOrCreateSelector<CaracteristiqueTemplate>(
      schema,
      selectAllCaracteristiqueTemplatesSelectors,
      selectCaracteristiqueTemplatesEntitiesDictionary,
      getRelationSelectors,
      caracteristiqueTemplateRelations,
      hydrateAll,
      'caracteristiqueTemplate'
    );
  } else {
    return selectAllCaracteristiqueTemplatesObject;
  }
}

export function selectAllCaracteristiqueTemplatesDictionary(
  schema: SelectSchema = {},
  customKey = 'caracteristiqueTemplates'
): Selector {
  return createSelector(selectAllCaracteristiqueTemplates(schema), result => {
    const res = { [customKey]: {} as Dictionary<CaracteristiqueTemplateEntityState> };
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < result.caracteristiqueTemplates.length; i++) {
      res[customKey][result.caracteristiqueTemplates[i].idCaracteristiqueTemplate] = result.caracteristiqueTemplates[i];
    }
    return res;
  });
}

export function selectOneCaracteristiqueTemplate(
  schema: SelectSchema = {},
  idCaracteristiqueTemplate: number
): Selector {
  if (schema.include) {
  const selectors: Selector[] = [selectOneCaracteristiqueTemplateDictionary(idCaracteristiqueTemplate)];
  selectors.push(...getRelationSelectors(schema, caracteristiqueTemplateRelations, 'caracteristiqueTemplate'));
  return (createSelector as any)(...selectors, hydrateOne);
  } else {
    return selectOneCaracteristiqueTemplateDictionaryWithoutChild(idCaracteristiqueTemplate);
  }
}

interface hydrateArgs {
  caracteristiqueTemplates: Dictionary<CaracteristiqueTemplateEntityState>;
  composantTemplates?: Dictionary<ComposantTemplateEntityState>;
  patrimoineHierarchieTemplates?: Dictionary<PatrimoineHierarchieTemplateEntityState>;
  societeCaracteristiques?: Dictionary<SocieteCaracteristiqueEntityState>;
  caracteristiqueChoixTemplates?: Dictionary<CaracteristiqueChoixTemplateEntityState>;
}

export function hydrateAll(...args: hydrateArgs[]): { caracteristiqueTemplates: (CaracteristiqueTemplate | null)[] } {
  const {
    caracteristiqueTemplates,
    composantTemplates,
    patrimoineHierarchieTemplates,
    societeCaracteristiques,
    caracteristiqueChoixTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  return {
    caracteristiqueTemplates: Object.keys(caracteristiqueTemplates).map(idCaracteristiqueTemplate =>
      hydrate(
        caracteristiqueTemplates[idCaracteristiqueTemplate] as CaracteristiqueTemplateEntityState,
        composantTemplates,
        patrimoineHierarchieTemplates,
        societeCaracteristiques,
        caracteristiqueChoixTemplates
      )
    )
  };
}

function hydrateOne(...args: hydrateArgs[]): { caracteristiqueTemplate: CaracteristiqueTemplateEntityState | null } {
  const {
    caracteristiqueTemplates,
    composantTemplates,
    patrimoineHierarchieTemplates,
    societeCaracteristiques,
    caracteristiqueChoixTemplates
  } = args.reduce((acc, value) => ({ ...acc, ...value }), {} as hydrateArgs);

  const caracteristiqueTemplate = Object.values(caracteristiqueTemplates)[0];
  return {
    caracteristiqueTemplate: hydrate(
      caracteristiqueTemplate as CaracteristiqueTemplateEntityState,
      composantTemplates,
      patrimoineHierarchieTemplates,
      societeCaracteristiques,
      caracteristiqueChoixTemplates
    )
  };
}

function hydrate(
  caracteristiqueTemplate: CaracteristiqueTemplateEntityState,
  composantTemplateEntities?: Dictionary<ComposantTemplateEntityState>,
  patrimoineHierarchieTemplateEntities?: Dictionary<PatrimoineHierarchieTemplateEntityState>,
  societeCaracteristiqueEntities?: Dictionary<SocieteCaracteristiqueEntityState>,
  caracteristiqueChoixTemplateEntities?: Dictionary<CaracteristiqueChoixTemplateEntityState>,
): CaracteristiqueTemplate | null {
  if (!caracteristiqueTemplate) {
    return null;
  }

  const caracteristiqueTemplateHydrated: CaracteristiqueTemplateEntityState = { ...caracteristiqueTemplate };
  if (composantTemplateEntities) {
    caracteristiqueTemplateHydrated.composantTemplate = composantTemplateEntities[caracteristiqueTemplate.composantTemplate as number] as ComposantTemplate;
  } else {
    delete caracteristiqueTemplateHydrated.composantTemplate;
  }
  if (patrimoineHierarchieTemplateEntities) {
    caracteristiqueTemplateHydrated.patrimoineHierarchieTemplate = patrimoineHierarchieTemplateEntities[caracteristiqueTemplate.patrimoineHierarchieTemplate as number] as PatrimoineHierarchieTemplate;
  } else {
    delete caracteristiqueTemplateHydrated.patrimoineHierarchieTemplate;
  }

  if (societeCaracteristiqueEntities) {
    caracteristiqueTemplateHydrated.societeCaracteristiques = ((caracteristiqueTemplateHydrated.societeCaracteristiques as number[]) || []).map(
      id => societeCaracteristiqueEntities[id]
    ) as SocieteCaracteristique[];
  } else {
    delete caracteristiqueTemplateHydrated.societeCaracteristiques;
  }

  if (caracteristiqueChoixTemplateEntities) {
    caracteristiqueTemplateHydrated.caracteristiqueChoixTemplates = ((caracteristiqueTemplateHydrated.caracteristiqueChoixTemplates as number[]) || []).map(
      id => caracteristiqueChoixTemplateEntities[id]
    ) as CaracteristiqueChoixTemplate[];
  } else {
    delete caracteristiqueTemplateHydrated.caracteristiqueChoixTemplates;
  }

  return caracteristiqueTemplateHydrated as CaracteristiqueTemplate;
}
