import { Injectable } from '@angular/core';
import {
  AssertEmptyComposantAttenduInterface,
  Campagne,
  CampagneUser,
  CampaignInfos,
  Composant,
  ComposantAttendu,
  DuplicateAndMoveInterface,
  Fichier,
  Patrimoine,
  RefetchAvancement,
  Valeur
} from '@get/api-interfaces';
import {
  CampagneActions,
  CampagneGeneratedActions,
  CampagneUserGeneratedActions,
  ComposantAttenduGeneratedActions,
  ComposantGeneratedActions,
  PatrimoineGeneratedActions,
  ValeurFichierGeneratedActions,
  ValeurGeneratedActions
} from '@get/store/actions';
import { AppState } from '@get/store/configs/reducers';
import { catchApiActions } from '@get/utils';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, tap } from 'rxjs';
import { GeneratedCampagneService } from './campagne-generated.service';
import { ComposantRelationsIds } from '@get/store/ids-interfaces';
import { CampagneApiService } from '@get/store/api-services';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CampagneService extends GeneratedCampagneService {
  constructor(store$: Store<AppState>, actions$: Actions, private readonly campagneApiService: CampagneApiService) {
    super(store$, actions$);
  }

  public getOneCampagnePatrimoines(
    idCampagne: number,
    params: any = {},
    getResult?: boolean
  ): void | Observable<Campagne> {
    this.store$.dispatch(CampagneActions.getOneCampagnePatrimoines({ idCampagne, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        CampagneGeneratedActions.normalizeManyCampagnesAfterUpsert,
        CampagneGeneratedActions.campagnesFailure,
        true
      );
    }
  }

  public downloadOneFichier(fichier: Fichier): void {
    this.campagneApiService
      .downloadOneFichier(fichier.idFichier)
      .pipe(tap(blob => saveAs(blob, fichier.originalName)))
      .subscribe();
  }

  public sendResponsibleMail(params: {
    emails: string[];
    campaignInfos: CampaignInfos;
    launch: boolean;
  }): Observable<CampagneUser[]> {
    this.store$.dispatch(CampagneActions.sendResponsibleMail(params));
    return catchApiActions(
      this.actions$,
      CampagneUserGeneratedActions.normalizeManyCampagneUsersAfterUpsert,
      CampagneGeneratedActions.campagnesFailure,
      true
    );
  }

  public refetchAvancement(ids: RefetchAvancement, getResult?: boolean): Observable<Patrimoine[]> | void {
    this.store$.dispatch(CampagneActions.refetchAvancement(ids));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        PatrimoineGeneratedActions.normalizeManyPatrimoinesAfterUpsert,
        CampagneGeneratedActions.campagnesFailure,
        true
      );
    }
  }

  public createManyComposants(composants: Partial<Composant>[], getResult?: boolean): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.createManyComposants({ composants }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertOneValeurReturnComposant(
    valeur: Partial<Valeur>,
    getResult = false,
    idCampagne?: number
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.upsertOneValeurAndReturnComposant({ valeur, params: { idCampagne } }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertManyValeursReturnComposant(
    valeurs: Partial<Valeur>[],
    getResult = false,
    idCampagne?: number
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.upsertManyValeursAndReturnComposants({ valeurs, params: { idCampagne } }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertOneComposant(
    composant: Partial<Composant>,
    ids: ComposantRelationsIds = {},
    getResult?: boolean
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.upsertOneComposant({ composant, ids }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertManyComposant(
    composants: Partial<Composant>[],
    oldComposants: Partial<Composant>[],
    getResult?: boolean
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.upsertManyComposant({ composants, oldComposants }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertOneValeur(valeur: Partial<Valeur>, getResult?: boolean): void | Observable<Valeur> {
    this.store$.dispatch(CampagneActions.upsertOneValeur({ valeur }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurGeneratedActions.normalizeManyValeursAfterUpsert,
        ValeurGeneratedActions.valeursFailure,
        true
      );
    }
  }

  public duplicateOneComposant(
    idComposant: number,
    ids: ComposantRelationsIds = {},
    getResult = false,
    params?: { idCampagne?: number }
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.duplicateOneComposant({ idComposant, ids, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public duplicateAndMoveComposant(
    body: DuplicateAndMoveInterface[],
    ids: ComposantRelationsIds = {},
    getResult = false,
    params?: { idCampagne?: number }
  ): void | Observable<Composant[]> {
    this.store$.dispatch(CampagneActions.duplicateAndMoveOneComposant({ body, ids, params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.normalizeManyComposantsAfterUpsert,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public upsertOneComposantModifyEspace(
    composant: Partial<Composant>,
    oldIdEspace: number,
    getResult?: boolean
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.upsertOneComposantModifyEspace({ composant, oldIdEspace }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.upsertManyComposantsSuccess,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public moveOneComposant(
    composant: Partial<Composant>,
    oldIdEspace: number,
    getResult?: boolean
  ): void | Observable<Composant> {
    this.store$.dispatch(CampagneActions.moveOneComposant({ composant, oldIdEspace }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.upsertManyComposantsSuccess,
        ComposantGeneratedActions.composantsFailure,
        true
      );
    }
  }

  public assertEmptyComposantAttendu(
    params: AssertEmptyComposantAttenduInterface,
    getResult?: boolean
  ): void | Observable<ComposantAttendu[]> {
    this.store$.dispatch(CampagneActions.assertEmptyComposantAttendu({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert,
        ComposantAttenduGeneratedActions.composantAttendusFailure,
        true
      );
    }
  }

  public assertEmptyTree(
    params: AssertEmptyComposantAttenduInterface,
    getResult?: boolean
  ): void | Observable<ComposantAttendu[]> {
    this.store$.dispatch(CampagneActions.assertEmptyTree({ params }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantAttenduGeneratedActions.normalizeManyComposantAttendusAfterUpsert,
        ComposantAttenduGeneratedActions.composantAttendusFailure,
        true
      );
    }
  }

  public uploadManyImages(files: File[], queryParams: { idOrganisation?: number } = {}): Observable<Fichier[]> {
    return this.campagneApiService.uploadManyImages(files, queryParams);
  }

  public uploadMany(files: File[], queryParams: { idOrganisation?: number } = {}): Observable<Fichier[]> {
    return this.campagneApiService.uploadMany(files, queryParams);
  }

  public deleteOneComposantWithComposantAttendu(idComposant: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CampagneActions.deleteOneComposantWithComposantAttendu({ idComposant }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ComposantGeneratedActions.deleteOneComposantSuccess,
        ComposantGeneratedActions.composantsFailure
      );
    }
  }

  public deleteOneValeurFichier(idValeurFichier: number, getResult?: boolean): void | Observable<number> {
    this.store$.dispatch(CampagneActions.deleteOneValeurFichier({ idValeurFichier }));
    if (getResult) {
      return catchApiActions(
        this.actions$,
        ValeurFichierGeneratedActions.deleteOneValeurFichierSuccess,
        ValeurFichierGeneratedActions.valeurFichiersFailure
      );
    }
  }
}
